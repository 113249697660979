



































































































import { Component, Prop, Mixins } from "vue-property-decorator";
import { Cert } from "@/types/ewelluser";
import AppPlaces from "#/components/AppPlaces.vue";
import FileUpload from "#/components/FileUpload.vue";
import DateInput from "#/components/DateInput.vue";
import RulesMixin from "#/mixins/rulesMixin";
import { CERTIFICATION } from "#/const/certification";
import { PREFECTURES } from "#/const/prefectures";

@Component({
  components: {
    AppPlaces,
    FileUpload,
    DateInput
  }
})
export default class SelectCertifications extends Mixins(RulesMixin) {
  @Prop({ default: 0 }) private readonly id!: number;

  @Prop() private readonly value!: Cert[];

  /** 資格区分 */
  private constCert = CERTIFICATION;

  /** 都道府県 */
  private constPrefs = PREFECTURES;

  /** 都道府県（准看護師） */
  private constPracticalNursePrefs = [
    { text: "未選択", value: 0 },
    ...PREFECTURES,
    { text: "関西広域連合", value: 99 }
  ];

  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";

  /** ファイルダウンロードAPIパス */
  private previewApiUrl = window.base_url + "/api/preview";

  /** 免許登録種別 */
  private registType = [
    {
      text: "未選択",
      value: 0
    },
    {
      text: "厚生労働省",
      value: 1
    },
    {
      text: "都道府県",
      value: 2
    }
  ];

  /** 資格区分（全て） */
  private get Certs() {
    return this.value;
  }

  /** 資格区分（選んだものだけ） */
  private get SelectCert() {
    return this.value.filter(v => v.is_check === 1);
  }
  private set SelectCert(val: Cert[]) {
    this.value.forEach(cert => {
      const isFind = val.find(
        sc => sc.certification_id === cert.certification_id
      );
      cert.is_check = isFind ? 1 : 0;
    });
  }

  /** 画像アップロードディレクトリパス */
  private get ImagePath() {
    return "mypage/" + this.id + "/user_info/certification";
  }

  /** 画像削除ボタンクリック */
  private async clickDeleteFile(cert: Cert) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      cert.file_path = "";
      cert.file_interface = { id: 0, path: "", comment: "" };
    }
  }

  /** ファイル変更時 */
  private changeFile(cert: Cert) {
    cert.file_path = cert.file_interface.path;
  }

  /** 資格区分のルール */
  private checkCert(): boolean | string {
    // 保健師・助産師・看護師・准看護師のいづれかが選択されているものをフィルター
    const targetCert = this.Certs.filter(cert => cert.is_check).filter(cert =>
      this.isMainBusiness(cert)
    );

    // 保健師・助産師・看護師・准看護師のいづれも選択されていない場合、警告なし
    if (targetCert.length === 0) {
      return true;
    }

    //一つ以上の登録番号が入力されていない場合
    if (!targetCert.some(cert => cert.regist_number != "")) {
      return "保健師・助産師・看護師・准看護師の内、一つ以上の登録番号を入力してください。";
    }

    return true;
  }

  /** 資格区分が看護師、准看護師、保健師、助産師のいづれかであるかどうか */
  private isMainBusiness(cert: Cert): boolean {
    return (
      cert.certification_id === CERTIFICATION.REGISTERED_NURSE ||
      cert.certification_id === CERTIFICATION.PRACTICAL_NURSE ||
      cert.certification_id === CERTIFICATION.MIDWIFE ||
      cert.certification_id === CERTIFICATION.PUBLIC_HEALTH_NURSE
    );
  }

  /** 資格区分がケアマネジャーであるかどうか */
  private isCareManager(cert: Cert): boolean {
    return cert.certification_id === CERTIFICATION.CARE_MANAGER;
  }

  /** 都道府県のルール */
  private requiredPref(pref: number, massage: string): string | boolean {
    return pref ? true : massage;
  }
}
