






































































import { Component, Mixins } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import AxiosMixin from "@/mixins/axiosMixin";
import { BreadItem } from "@/types";

@Component
export default class extends Mixins(AxiosMixin) {
  /** ユーザー情報 */
  private user: Ewelluser = DefaultEwelluser();
  /** 契約期間 */
  private range: unknown = "";
  /** 通知日 */
  private date: unknown = "";

  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "契約情報",
      disabled: false,
      to: "/ibow2/contract/info",
      exact: true
    },
    {
      text: "アカウント通知書",
      disabled: true,
      to: ""
    }
  ];

  /** iBowマイページURL */
  private get authUrl() {
    return window.auth_frontend_url;
  }

  /** iBowURL */
  private get frontendUrl() {
    return window.ibow_frontend_url;
  }

  created(): void {
    this.range = this.$route.query.range;
    this.date = this.$route.query.date;
    this.fetch();
  }

  /** データ取得 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
    });
  }
}
