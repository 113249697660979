import { User } from "@/types";

export const name = "master";

export const SET_MASTER = "SET_MASTER";

export interface MasterState {
  loginUser: User;
  error: boolean;
}

export interface SetMaster {
  loginUser: User;
}
