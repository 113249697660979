













import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop() value!: number;

  @Prop() label!: string;

  @Prop() disabled!: boolean;

  @Prop() cols!: string;

  @Emit("input") input(newValue: number) {
    return newValue;
  }

  private get Value() {
    return this.value;
  }
  private set Value(newValue: number) {
    this.input(newValue);
  }
}
