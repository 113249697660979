


































import { Component, Emit, Prop } from "vue-property-decorator";
import * as appCopy from "#/utility/appCopy";
import { mixins } from "vue-class-component";
import AxiosMixin from "@/mixins/axiosMixin";
import { DefaultLayer, Layer } from "#/components/calendar/common";

@Component
export default class Navigation extends mixins(AxiosMixin) {
  /** 展開状態 */
  @Prop({ default: false }) value!: boolean;

  /** 展開状態変更時 */
  @Emit("input") private input(value: boolean) {
    return value;
  }

  /** 保存時 */
  @Emit() private save() {
    return;
  }

  /** キャンセル時 */
  @Emit() private cancel() {
    return;
  }

  private layer: Layer = DefaultLayer(""); // カレンダーレイヤー

  /** 展開状態 */
  private get Value() {
    return this.value;
  }
  private set Value(value: boolean) {
    this.input(value);
  }

  /** ダイアログを開く */
  public open(layer: Layer) {
    this.layer = appCopy.deepCopy(layer);
    this.Value = true;
  }

  /** 保存 */
  private clickSave() {
    this.postJsonCheck(
      window.base_url + "/api/mycalendar/layer/save",
      { layer: this.layer },
      () => {
        this.Value = false;
        this.save();
      }
    );
  }

  /** キャンセル */
  private clickCancel() {
    this.Value = false;
    this.cancel();
  }
}
