












































import { Component, Emit, Vue, Prop, Ref } from "vue-property-decorator";

@Component
export default class PhoneChange extends Vue {
  @Ref("form") private readonly form!: { validate: () => boolean };

  /** 表示フラグ */
  @Prop({ default: false }) private isDisplay!: boolean;

  /** ローディング */
  @Prop({ default: 0 }) private loading!: number;

  /** 送信 */
  @Emit() private submit() {
    return;
  }

  /** 削除 */
  @Emit() private del() {
    return;
  }

  /** キャンセル */
  @Emit() private cancel() {
    return;
  }

  /** バリデーション */
  private async validate() {
    if (!this.form.validate()) {
      return;
    }
    this.submit();
  }
}
