



















































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { BreadItem } from "@/types";
import Cookies from "js-cookie";

@Component
export default class extends Mixins(AxiosMixin) {
  /** 削除確認チェックボックス */
  private agree = false;

  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "アカウント情報の確認",
      disabled: false,
      to: "/userinfo",
      exact: true
    },
    {
      text: "アカウント情報の編集",
      disabled: false,
      to: "/userinfo/edit",
      exact: true
    },
    {
      text: "アカウント情報の削除確認",
      disabled: true,
      to: ""
    }
  ];

  /** iBowアカウント削除 */
  private deleteData() {
    this.postJsonCheck(window.base_url + "/api/accountinfo/delete", {}, () => {
      window.token = "";
      Cookies.set("token", "", { domain: window.cookie_domain });
      window.reftoken = "";
      Cookies.set("reftoken", "", { domain: window.cookie_domain });
      location.href = "/";
    });
  }

  /** 戻る処理 */
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/userinfo");
    }
  }
}
