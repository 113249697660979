



























































import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import BiometricRegistDialog from "@/components/biometric/BiometricRegistDialog.vue";
import { DataTableHeader } from "vuetify/types/index";

@Component({
  components: { BiometricRegistDialog }
})
export default class extends Vue {
  @Prop({ default: () => DefaultEwelluser() }) private user!: Ewelluser;

  @Emit() private saveEwellUser(
    afterFunc: () => void = () => {
      return;
    }
  ) {
    return afterFunc;
  }

  /** 生体認証情報登録ヘッダー */
  private webauth_headers: DataTableHeader[] = [
    {
      text: "端末名",
      value: "device_name",
      width: "",
      sortable: true,
      cellClass: ""
    },
    {
      text: "登録日時",
      value: "created_at",
      width: "",
      sortable: true,
      cellClass: ""
    },
    {
      text: "",
      value: "actions",
      width: "",
      sortable: false,
      cellClass: ""
    }
  ];

  /** 端末名 */
  private deviceName = "";

  /** 端末名変更ダイアログの起動状態 */
  private isOpenDeviceNameChangeDialog = false;

  /** 生体認証情報 端末名変更ダイアログを開く */
  private openDeviceNameChangeDialog(index: number) {
    this.deviceName = this.user.ewell_user_credentials[index].device_name;
    this.isOpenDeviceNameChangeDialog = true;
  }

  /** 生体認証情報 端末名変更 */
  private saveDeviceName(index: number) {
    this.user.ewell_user_credentials[index].device_name = this.deviceName;
    this.saveEwellUser(() => {
      this.isOpenDeviceNameChangeDialog = false;
    });
  }

  /** 生体認証情報解除 */
  private async deleteBiometricAuth(index: number) {
    if (!(await this.$openConfirm("解除します。よろしいですか？"))) {
      return;
    }
    this.user.ewell_user_credentials.splice(index, 1);
    this.saveEwellUser();
  }
}
