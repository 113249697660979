














































































import { Component, Emit, Vue } from "vue-property-decorator";
import {
  CalendarDispEvent,
  DefaultEvent,
  EditRangeDiv,
  EditRange
} from "#/components/calendar/common";

@Component({})
export default class ShowEventDialog extends Vue {
  /** データを編集する */
  @Emit() private edit(): {
    event: CalendarDispEvent;
    editRange: EditRangeDiv;
  } {
    this.close();
    return { event: this.event, editRange: this.editRange };
  }

  /** ダイアログ展開状態 */
  private dialog = false;

  /** イベント */
  private event = DefaultEvent();

  /** 編集範囲 */
  private readonly EditRange = EditRange;

  /** 選択中の変更範囲 */
  private editRange = EditRangeDiv.This;

  /** 場所URLフラグ */
  private get IsLocateUrl(): boolean {
    return (
      this.event.staff_schedule.locate.indexOf("http://") === 0 ||
      this.event.staff_schedule.locate.indexOf("https://") === 0
    );
  }

  /** ダイアログを開く */
  public open(event: CalendarDispEvent) {
    this.event = event;
    this.editRange = EditRangeDiv.This;
    this.dialog = true;
  }

  // ダイアログ閉じる
  public close() {
    this.dialog = false;
  }

  private selectRange(range: EditRangeDiv) {
    this.editRange = range;
    this.edit();
  }
}
