import { Vue, Component } from "vue-property-decorator";
import { State } from "vuex-class";
import * as master from "@/store/modules/master/types";
import { User } from "@/types";

@Component({})
export default class UtilMixin extends Vue {
  @State(master.name) masterState!: master.MasterState;

  public get loginUser(): User {
    return this.masterState.loginUser as User;
  }
}
