







































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Link } from "../types";

@Component
export default class AppSideBar extends Vue {
  /** リンクリスト */
  @Prop({ default: () => [] }) links!: Link[];

  /** 現在有効なリンク */
  @Prop({ default: () => ({}) }) value!: Link;

  /** リンクをクリック */
  @Emit("clickItem") private clickItem(link: Link, index: number) {
    this.Value = link;
    return { link: link, index: index };
  }

  /** 現在有効なリンクを変更  */
  @Emit("input") private input(newValue: Link) {
    return newValue;
  }

  /** ナビゲーション開閉状態 */
  private openNav = true;

  /** メニュー展開アイコン */
  private get NavOpenerIcon() {
    return this.openNav ? "chrvron-left" : "chrvron-right";
  }

  private get Value(): Link {
    return this.value;
  }

  private set Value(newValue: Link) {
    this.input(newValue);
  }
}
