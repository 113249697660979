import { Vue, Component, Prop } from "vue-property-decorator";
import { AxiosInstance, AxiosResponse, AxiosError } from "axios";

interface AttestationOption {
  challenge: string;
  rp: {
    id: string;
    name: string;
  };
  user: {
    id: string;
    name: string;
    displayName: string;
  };
  pubKeyCredParams: PublicKeyCredentialParameters[];
  attestation: AttestationConveyancePreference;
  authenticatorSelection: {
    residentKey?: "discouraged" | "preferred" | "required";
    authenticatorAttachment: AuthenticatorAttachment;
    userVerification: UserVerificationRequirement;
  };
  excludeCredentials?: AllowCredentials[];
  timeout: number;
}

interface AssertionOption {
  allowCredentials: AllowCredentials[];
  challenge: string;
  rpId: string;
  userVerification: UserVerificationRequirement;
  timeout: number;
}

interface AllowCredentials {
  id: string;
  transports?: AuthenticatorTransport[];
  type: PublicKeyCredentialType;
}

/**
 * v-data-tableのフィルタMixin
 */
@Component
export default class extends Vue {
  /** EwellUserId */
  @Prop() ewellUserId!: number;

  /** ログインID */
  protected userId = "";

  /** パスワード */
  protected password = "";

  /** キーコード */
  private keyCode = "";

  /** ローディングフラグ */
  protected login_loading = false;

  /** エラーメッセージ */
  protected errorMessages = "";

  /** ログイン処理 */
  protected login(
    baseUrl: string,
    axios: AxiosInstance,
    fingerprint: string,
    setToken: (res: AxiosResponse) => void,
    openAlertDialog: (message: string) => void
  ) {
    this.login_loading = true;
    axios
      .post(baseUrl + "/auth/login", {
        user_id: this.userId,
        password: this.password,
        device_hash: fingerprint
      })
      .then((res: AxiosResponse) => {
        this.login_loading = false;
        setToken(res);
      })
      .catch(async (error: AxiosError) => {
        this.login_loading = false;
        this.password = "";
        if (error.response && error.response.status == 401) {
          this.errorMessages = error.response.data.message;
        } else {
          openAlertDialog("サーバでエラーが発生しました。\n(" + error + ")");
        }
      });
  }

  /** キーコードでのログイン */
  protected loginKeyCode(
    baseUrl: string,
    axios: AxiosInstance,
    query_hash: string,
    setToken: (res: AxiosResponse) => void,
    openAlertDialog: (message: string) => void
  ) {
    this.login_loading = true;
    axios
      .post(baseUrl + "/auth/double-login", {
        key_code: this.keyCode,
        token: query_hash
      })
      .then((res: AxiosResponse) => {
        this.login_loading = false;
        setToken(res);
      })
      .catch(async (error: AxiosError) => {
        this.login_loading = false;
        if (
          error.response &&
          (error.response.status == 401 || error.response.data.code == 101)
        ) {
          this.errorMessages = error.response.data.message;
        } else {
          openAlertDialog("サーバでエラーが発生しました。\n(" + error + ")");
        }
      });
  }

  /** 生体認証の開始 */
  protected loginWebauthnBegin(
    baseUrl: string,
    axios: AxiosInstance,
    query_hash: string,
    successFunc: (res: AxiosResponse) => void,
    openAlertDialog: (message: string) => void
  ) {
    this.login_loading = true;
    axios
      .post(baseUrl + "/webauthn/assertion/begin", {
        ewell_user_id: this.ewellUserId
      })
      .then((res: AxiosResponse) => {
        const option: AssertionOption = res.data.webauthn_response;
        return navigator.credentials.get({
          publicKey: {
            allowCredentials: option.allowCredentials.map(credential => {
              return {
                id: this.base64StringToArrayBuffer(credential.id),
                transports: credential.transports,
                type: credential.type
              };
            }),
            challenge: this.base64StringToArrayBuffer(option.challenge),
            rpId: option.rpId,
            timeout: option.timeout,
            userVerification: option.userVerification
          }
        });
      })
      .then(credential => {
        if (credential !== null) {
          this.loginWebauthnComplete(
            baseUrl,
            axios,
            query_hash,
            successFunc,
            openAlertDialog,
            credential as PublicKeyCredential
          );
        } else {
          openAlertDialog("認証に失敗しました");
        }
      })
      .catch((error: AxiosError) => {
        this.login_loading = false;
        //キャンセルの場合
        if (error.name === "NotAllowedError") {
          return;
        }
        if (
          error.response &&
          (error.response.status == 401 || error.response.data.code == 101)
        ) {
          this.errorMessages = error.response.data.message;
        } else {
          openAlertDialog("サーバでエラーが発生しました。\n(" + error + ")");
        }
      });
  }

  /** 生体認証の完了 */
  private loginWebauthnComplete(
    baseUrl: string,
    axios: AxiosInstance,
    query_hash: string,
    successFunc: (res: AxiosResponse) => void,
    openAlertDialog: (message: string) => void,
    credential: PublicKeyCredential
  ) {
    const assertionResponse = credential.response as AuthenticatorAssertionResponse;
    let userHandle = "";
    if (assertionResponse.userHandle) {
      userHandle = this.arrayBufferToBase64(assertionResponse.userHandle);
    }
    const assertion = {
      id: credential.id,
      rawId: this.arrayBufferToBase64(credential.rawId),
      type: credential.type,
      response: {
        authenticatorData: this.arrayBufferToBase64(
          assertionResponse.authenticatorData
        ),
        clientDataJSON: this.arrayBufferToBase64(
          assertionResponse.clientDataJSON
        ),
        signature: this.arrayBufferToBase64(assertionResponse.signature),
        userHandle
      }
    };

    axios
      .post(baseUrl + "/webauthn/assertion/complete", {
        assertion,
        ewell_user_id: this.ewellUserId,
        token: query_hash
      })
      .then((res: AxiosResponse) => {
        this.login_loading = false;
        successFunc(res);
      })
      .catch(async (error: AxiosError) => {
        this.login_loading = false;
        if (error.response && error.response.data.code == 101) {
          this.errorMessages = error.response.data.message;
        } else {
          await openAlertDialog(
            "サーバでエラーが発生しました。\n(" + error + ")"
          );
        }
      });
  }

  /** 生体認証の登録開始 */
  protected registerWebauthnBegin(
    baseUrl: string,
    postJsonCheck: (
      url: string,
      param: unknown,
      sucessFunc: (res: AxiosResponse) => void,
      catchFunc?: (error: AxiosError) => void
    ) => void,
    openAlertDialog: (message: string) => void,
    updateDataFunc: () => void = () => {
      return;
    },
    cancelFunc: () => void = () => {
      return;
    }
  ) {
    this.login_loading = true;
    postJsonCheck(
      baseUrl + "/api/webauthn/attestation/begin",
      {},
      async res => {
        const option: AttestationOption = res.data.webauthn_response;

        let excludeCredentials: PublicKeyCredentialDescriptor[] = [];
        if (option.excludeCredentials !== undefined) {
          excludeCredentials = option.excludeCredentials.map(c => {
            return {
              id: this.base64StringToArrayBuffer(c.id),
              transports: c.transports,
              type: c.type
            } as PublicKeyCredentialDescriptor;
          });
        }

        navigator.credentials
          .create({
            publicKey: {
              challenge: this.base64StringToArrayBuffer(option.challenge),
              rp: {
                id: option.rp.id,
                name: option.rp.name
              },
              user: {
                id: this.base64StringToArrayBuffer(option.user.id),
                name: option.user.name,
                displayName: option.user.displayName
              },
              excludeCredentials,
              pubKeyCredParams: option.pubKeyCredParams,
              attestation: option.attestation,
              timeout: option.timeout,
              authenticatorSelection: {
                userVerification:
                  option.authenticatorSelection.userVerification,
                authenticatorAttachment:
                  option.authenticatorSelection.authenticatorAttachment
              }
            }
          })
          .then(c => {
            if (c !== null) {
              this.registerWebauthnComplete(
                baseUrl,
                postJsonCheck,
                openAlertDialog,
                c as PublicKeyCredential,
                updateDataFunc
              );
            }
          })
          .catch((e: Error) => {
            this.login_loading = false;
            if (e.name === "NotAllowedError") {
              // キャンセルの可能性あり
              // do nothing
              cancelFunc();
            } else if (e.name === "InvalidStateError") {
              openAlertDialog("登録済みのデバイスの可能性があります");
            } else {
              openAlertDialog("登録に失敗しました");
            }
          });
      }
    );
  }

  /** 生体認証の登録完了 */
  private registerWebauthnComplete(
    baseUrl: string,
    postJsonCheck: (
      url: string,
      param: unknown,
      sucessFunc: (res: AxiosResponse) => void,
      catchFunc?: (error: AxiosError) => void
    ) => void,
    openAlertDialog: (message: string) => void,
    credential: PublicKeyCredential,
    updateDataFunc: () => void = () => {
      return;
    }
  ) {
    const attestationResponse = credential.response as AuthenticatorAttestationResponse;
    const data = {
      id: credential.id,
      rawId: this.arrayBufferToBase64(credential.rawId),
      type: credential.type,
      response: {
        attestationObject: this.arrayBufferToBase64(
          attestationResponse.attestationObject
        ),
        clientDataJSON: this.arrayBufferToBase64(
          attestationResponse.clientDataJSON
        )
      }
    };

    postJsonCheck(
      baseUrl + "/api/webauthn/attestation/complete",
      data,
      async () => {
        this.login_loading = false;
        await openAlertDialog("登録しました");
        updateDataFunc();
      },
      () => {
        this.login_loading = false;
        openAlertDialog("登録失敗");
      }
    );
  }

  private arrayBufferToBase64(buffer: ArrayBuffer) {
    const binary = String.fromCharCode(...new Uint8Array(buffer));
    return window
      .btoa(binary)
      .replace(/\//g, "_")
      .replace(/\+/g, "-")
      .replace(/=+$/g, "");
  }

  private base64StringToArrayBuffer(base64String: string) {
    const replaced = base64String.replace(/_/g, "/").replace(/-/g, "+");
    const decoded = window.atob(replaced);
    return Uint8Array.from(decoded, c => c.charCodeAt(0));
  }
}
