
















































import { Component, Prop, Emit, Mixins, Watch } from "vue-property-decorator";
import DatePickerYearFormatMixin from "./../../mixins/datePickerYearFormatMixin";
import * as appDate from "./../../utility/appDate";
import AppButton from "./../AppButton.vue";

@Component({
  components: {
    AppButton
  }
})
export default class CalendarFocusChange extends Mixins(
  DatePickerYearFormatMixin
) {
  @Prop({ default: "" }) private type!: string;
  @Prop({ default: "" }) private value!: string;

  @Emit() private input(val: string) {
    return val;
  }

  @Emit() private change(dateStr: string) {
    return dateStr;
  }

  @Watch("type") private changeType() {
    if (this.type === "month") {
      const [yearStr, monthStr] = this.date.split("-");
      this.monthValue = yearStr + monthStr;
    } else {
      this.dateValue = this.date;
    }
  }

  @Watch("value") private changeDay() {
    if (this.type === "month") {
      const [yearStr, monthStr] = this.date.split("-");
      this.monthValue = yearStr + monthStr;
    } else {
      this.dateValue = this.date;
    }
  }

  // picker用value
  private monthValue = appDate.thisMonth();
  private dateValue = appDate.today();
  // pickerの表示フラグ
  private pickerFlag = false;

  // 稼働月選択時時点のfocus日を保持(dd)
  private focusDate = appDate.today().slice(-2);

  private get date(): string {
    return this.value;
  }
  private set date(val: string) {
    let newValue = val;
    if (this.type === "month") {
      newValue = val + "-" + this.focusDate;
      // 月末の時、月だけ変えると翌月になってしまう(例:12/31→2/31→3/1)
      // そのため検知して、変更月の月末に補正する
      const changedDate = appDate.strToDate(newValue);
      if (changedDate.getMonth() + 1 !== +newValue.slice(5, 7)) {
        changedDate.setDate(0);
        newValue = appDate.dateToStr(changedDate, "yyyy-MM-dd");
      }
    }
    this.input(newValue);
  }

  // 週表示の場合
  private get IsWeek(): boolean {
    if (this.type === "week") {
      return true;
    }
    return false;
  }

  // 月表示時のpicker値変更
  public monthChange(dateStr: string) {
    this.date = dateStr;
    this.change(dateStr);
  }
  // 週日表示時のpicker値変更
  public dateChange(dateStr: string) {
    this.date = dateStr;
    // 月表示用に日時を保存
    const dateStrlist = dateStr.split("-");
    this.focusDate = dateStrlist[2];
    this.change(dateStr);
  }
}
