





































import {
  Vue,
  Component,
  Mixins,
  Emit,
  Ref,
  Prop
} from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";
import { Choice } from "../types";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppIcon
  }
})
export default class AppCombobox extends Mixins(RulesSaveMixin) {
  @Ref("combobox") private readonly combobox!: Vue;

  @Prop() label!: string;
  @Prop() value!: string;
  @Prop() items!: Choice[];
  @Prop() remarks!: string;
  @Prop({ default: "48px" }) height!: string;
  @Prop({ default: "100%" }) width!: string;
  @Prop({ default: "225px" }) minWidth!: string;
  @Prop({ default: false }) isNeed!: boolean;
  @Prop({ default: false }) multiple!: boolean;
  @Prop({ default: "text" }) itemText!: string;
  @Prop({ default: "text" }) itemValue!: string;
  @Prop({ default: false }) returnObject!: boolean;

  @Emit()
  private input(newValue: string): string {
    return newValue;
  }

  /** v-combobox内部で使われている入力要素 */
  private innerInputElem: HTMLInputElement | null = null;

  private get Label(): string {
    if (this.isNeed) return "*" + this.label;
    return this.label;
  }

  private get Value(): string {
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }

  /** 内部入力欄から発せられるInpuTEventの、ブラウザ差異を吸収する */
  private adjustInput(e: Event) {
    const ie = e as InputEvent;
    const target = e.target as HTMLInputElement;
    // Safariのみ：IME入力を確定すると、一瞬deleteCompositionTextで値を消してしまうので、それを防ぐ
    if (ie.inputType === "deleteCompositionText") {
      e.stopImmediatePropagation();
      return;
    }
    // 2文字以上を消す際に、最後の1文字が2回押さないと消えないので、強制的に消す
    if (!target.value) {
      this.$nextTick(() => {
        this.Value = "";
      });
      return;
    }
  }

  /** フォーカスが外れた時、遅延なく値を反映する */
  private applyValue(e: Event) {
    this.Value = (e.target as HTMLInputElement).value;
  }

  mounted() {
    this.innerInputElem = this.combobox.$el.querySelector("input[type=text]");
    if (this.innerInputElem) {
      this.innerInputElem.addEventListener("input", this.adjustInput, true);
      this.innerInputElem.addEventListener("blur", this.applyValue, true);
    }
  }

  beforeDestroy() {
    if (this.innerInputElem) {
      this.innerInputElem.removeEventListener("input", this.adjustInput, true);
      this.innerInputElem.removeEventListener("blur", this.applyValue, true);
    }
  }
}
