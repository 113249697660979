



























































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import FileUpload from "#/components/FileUpload.vue";

@Component({
  components: { FileUpload }
})
export default class extends Vue {
  @Prop({ default: () => DefaultEwelluser() }) private user!: Ewelluser;

  @Emit() private saveEwellUser(): void {
    return;
  }

  /** ファイルアップロードAPIパス */
  private uploadApiUrl = window.base_url + "/api/upload";

  /** ファイルダウンロードAPIパス */
  private previewApiUrl = window.base_url + "/api/preview";

  /** ファイルエリアヘッダー */
  private file_headers: DataTableHeader[] = [
    {
      text: "ファイル名",
      value: "name",
      width: "",
      sortable: true,
      cellClass: ""
    },
    {
      text: "コメント",
      value: "comment",
      width: "",
      sortable: true,
      cellClass: ""
    },
    {
      text: "",
      value: "actions",
      width: "",
      sortable: false,
      cellClass: ""
    }
  ];

  /** ファイルアップロードで即座に編集ダイアログを表示するか */
  private init_dialog = false;

  /** 画像パス */
  private get imagePath() {
    return "mypage/" + this.user.id + "/user_info/";
  }

  /** 画像新規作成 */
  private clickNewFile() {
    this.init_dialog = true;
    this.user.ewell_user_files.push({
      id: 0,
      ewell_user_id: 0,
      sort_no: 0,
      path: "",
      comment: ""
    });
  }

  /** 画像キャンセル */
  private cancelPath() {
    this.init_dialog = false;
    this.user.ewell_user_files = this.user.ewell_user_files.filter(
      file => file.path != ""
    );
  }

  /** 画像削除ボタンクリック */
  private async clickDeleteFile(index: number) {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.user.ewell_user_files.splice(index, 1);
      this.saveEwellUser();
    }
  }

  /** 画像保存 */
  private saveFile() {
    this.init_dialog = false;
    this.user.ewell_user_certifications = this.user.ewell_user_certifications.filter(
      x => x.is_check === 1
    );
    this.saveEwellUser();
  }

  /** 画像削除 */
  private deleteFile(index: number) {
    this.user.ewell_user_files.splice(index, 1);
    this.saveEwellUser();
  }
}
