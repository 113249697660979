































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import UtilMixin from "@/mixins/utilMixin";
import { ServiceLinksByCategories } from "@/model/serviceLink/type";
import LinkCard from "@/components/serviceLink/linkCard.vue";
import Title from "@/components/common/Title.vue";

@Component({ components: { LinkCard, Title } })
export default class extends Mixins(AxiosMixin, UtilMixin) {
  /** サービスリンク一覧 */
  private serviceLinksByCategories: ServiceLinksByCategories[] = [];

  mounted(): void {
    this.fetch();
  }

  //データ更新
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/service_links/category/get",
      {
        is_posted: true,
        show_service_links: true,
        sort_type: 2
      },
      res => {
        this.serviceLinksByCategories = res.data.service_links_by_categories;
      }
    );
  }

  private toLink(url: string) {
    this.$router.push({
      path: url
    });
  }
}
