import { Ewelluser } from "./ewelluser";

/** eWeLL利用者の初期値 */
export function DefaultEwelluser(): Ewelluser {
  return {
    id: 0,
    system_id: "",
    icon_path: "",
    user_id: "",
    pass_exp_date: "",
    user_div: 1,
    default_office_staff_id: 0,
    mail_address: "",
    tel_no: "",
    family_name: "",
    first_name: "",
    family_name_furigana: "",
    first_name_furigana: "",
    sex: 0,
    birthday: "",
    post_code: "",
    pref: 0,
    city: "",
    area: "",
    building: "",
    address: "",
    cert: "",
    is_nurse_practitioner: false,
    practitioner: "",
    sex_name: "",
    icon_full_path: "",
    ewell_user_contacts: [],
    ewell_user_files: [],
    ewell_user_certifications: [],
    ewell_user_practitioners: [],
    ewell_user_credentials: [],
    ewell_user_area_package_trainings: [],
    ewell_user_practitioner_parent_divs: [],
    practitioner_training_completion: 0,
    area_package_training: "",
    ewell_user_private: {
      id: 0,
      ewell_user_id: 0,
      user_id: 0,
      system_id: 0,
      mail_address: 0,
      tel_no: 0,
      name: 0,
      birthday: 0,
      sex: 0,
      address: 0,
      practitioner_training_completion: 0,
      emergency_contact_name: 0,
      emergency_contact_family_relationship: 0,
      emergency_contact_tel_no: 0,
      emergency_contact_mail_address: 0,
      practitioner: 0,
      certification: 0,
      area_package_training: 0
    }
  };
}
