<template>
  <v-footer app color="footer">
    <v-col class="text-center">
      Copyright© eWeLL Corporation. All right reserved.
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "AppFooter"
};
</script>
