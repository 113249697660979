import { ReceiptActingMail } from "./receiptActingMail";
import { ReceiptLink } from "./receiptLink";
import { ReceiptLinkSetting } from "./receiptLinkSetting";

export interface AgreementHistory {
  id: number; //'契約履歴ID',
  agreement_id: number; //'契約ID',
  confflag: number; //'契約状態\n-2:受付中\n-1:確認中\n0:リリース前\n1:契約中（リリース中）\n2:変更届申請中',
  office_count: number; //'契約事業所数',
  co_name: string; //'契約者名',
  co_code: string; //'契約コード',
  post_code: string; //'郵便番号',
  pref: number; //'都道府県',
  city: string; //'市区町村',
  area: string; //'番地',
  building: string; //'建物名',
  tel_city: string; //'電話番号',
  tel_area: string; //'電話番号',
  tel_num: string; //'電話番号',
  fax_area: string; //'FAX番号',
  fax_city: string; //'FAX番号',
  fax_num: string; //FAX番号
  remarks: string; //備考
  receipt_agency_div: number; //レセプト代行使用フラグ\n0:未使用\n1:使用
  u_start_dt: string; //開始日
  u_end_dt: string; //終了日
  created_at: string; //作成日
  updated_at: string; //更新日時
  deleted_at?: string; //削除日時
  u_start_dt_wareki: string; //契約開始日（和暦）
  u_end_dt_wareki: string; //契約終了日（和暦）
  receipt_acting_mails: ReceiptActingMail[]; //レセプト代行メールアドレス
  receipt_links: ReceiptLink[]; //レセプト連携
  receipt_link_settings: ReceiptLinkSetting[]; //レセプト連携設定
}

export function DefaultAgreementHistory(): AgreementHistory {
  return {
    id: 0, //'契約履歴ID',
    agreement_id: 0, //'契約ID',
    confflag: 0, //'契約状態\n-2:受付中\n-1:確認中\n0:リリース前\n1:契約中（リリース中）\n2:変更届申請中',
    office_count: 0, //'契約事業所数',
    co_name: "", //'契約者名',
    co_code: "", //'契約コード',
    post_code: "", //'郵便番号',
    pref: 0, //'都道府県',
    city: "", //'市区町村',
    area: "", //'番地',
    building: "", //'建物名',
    tel_city: "", //'電話番号',
    tel_area: "", //'電話番号',
    tel_num: "", //'電話番号',
    fax_area: "", //'FAX番号',
    fax_city: "", //'FAX番号',
    fax_num: "", //FAX番号
    remarks: "", //備考
    receipt_agency_div: 0, //レセプト代行使用フラグ\n0:未使用\n1:使用
    u_start_dt: "", //開始日
    u_end_dt: "", //終了日
    created_at: "", //作成日
    updated_at: "", //更新日時
    deleted_at: "", //削除日時
    u_start_dt_wareki: "", //契約開始日（和暦）
    u_end_dt_wareki: "", //契約終了日（和暦）
    receipt_acting_mails: [], //レセプト代行メールアドレス
    receipt_links: [], //レセプト連携
    receipt_link_settings: [] //レセプト連携設定
  };
}
