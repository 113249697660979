import { render, staticRenderFns } from "./ChangeForm.vue?vue&type=template&id=0a129a00&scoped=true&"
import script from "./ChangeForm.vue?vue&type=script&lang=ts&"
export * from "./ChangeForm.vue?vue&type=script&lang=ts&"
import style0 from "./ChangeForm.vue?vue&type=style&index=0&id=0a129a00&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a129a00",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VForm,VRow,VSpacer})
