import { CalendarLayer } from "#/model/schedule/calendarLayer";

export interface CalendarLayerStaff {
  id: number;
  ewell_user_id: number;
  ibow_nickname: string;
  job: string;
  office_staff_id: string;
  unique_id: number;
  office_id: number;
}

export interface CalendarLayerAddStaff extends CalendarLayer {
  staff: CalendarLayerStaff;
}

export const DefaultCalendarLayerStaff = (): CalendarLayerStaff => ({
  id: 0,
  ewell_user_id: 0,
  ibow_nickname: "",
  job: "",
  office_staff_id: "",
  unique_id: 0,
  office_id: 0
});

export const DefaultCalendarLayerSetColor = (
  color: string
): CalendarLayerAddStaff => ({
  id: 0,
  ewell_user_id: 0,
  agreement_id: 0,
  staff_id: 0,
  name: "",
  color: color,
  invalid_edit: 0,
  public: 0,
  events: [],
  created_at: "",
  updated_at: "",
  deleted_at: "",
  staff: DefaultCalendarLayerStaff()
});

export const DefaultCalendarLayer = DefaultCalendarLayerSetColor("");

/** カレンダー表示状態 */
export interface Document {
  selectLayers: { layerId: number; checked: boolean }[]; // 選択中のレイヤー
  openAcrodion: number[]; // アコーディオンオープン状態
  type: string; // カレンダータイプ
}
export const DefaultDocument = (): Document => ({
  selectLayers: [],
  openAcrodion: [0],
  type: "week"
});

/** 職員カレンダーインターフェース */
export interface SelectStaff {
  id: number; // 職員ID
  checked: number[]; // チェック状態
  color: string;
}
export interface SetColor {
  setting: SetColorItem[];
}
export interface SetColorItem {
  id: number; // 職員ID
  color: string;
}
export const DefaultDocumentSetColor = (): SetColor => ({
  setting: []
});

/** カレンダー表示状態 */
export interface DocStaffCalendar {
  selectLayers: { layerId: number; checked: boolean }[]; // 選択中のレイヤー
  openAcrodion: number[]; // アコーディオンオープン状態
  type: string; // カレンダータイプ
  showStaffs: SelectStaff[]; // 表示職員
}
export const DefaultDocStaffCalendar = (): DocStaffCalendar => ({
  selectLayers: [],
  openAcrodion: [0, 1],
  type: "month",
  showStaffs: []
});
