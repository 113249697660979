











import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class UserInfoItem extends Vue {
  @Prop() label!: string;
  @Prop() value!: string;
  @Prop({ default: true }) needDivider!: boolean;
}
