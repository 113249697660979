


















import { Component, Vue, Prop } from "vue-property-decorator";
import AppToolTip from "./AppToolTip.vue";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppToolTip,
    AppIcon
  }
})
export default class AppSecondTitle extends Vue {
  /** 表示文言 */
  @Prop() private readonly label!: string;
  /** 説明 */
  @Prop() private readonly hint!: string;
}
