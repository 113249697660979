









































































import { Component, Mixins } from "vue-property-decorator";
import { AgreementHistory, DefaultAgreementHistory } from "@/types/ibow2";
import AxiosMixin from "@/mixins/axiosMixin";
import AppPlaces from "#/components/AppPlaces.vue";
import { BreadItem } from "@/types";

@Component({ components: { AppPlaces } })
export default class extends Mixins(AxiosMixin) {
  /** 法人 */ private agreement: unknown;

  /** 法人情報 */ private agreement_info: AgreementHistory = DefaultAgreementHistory();

  /** 法人情報(変更前) */ private agreement_info_before: AgreementHistory = DefaultAgreementHistory();

  /** 法人ID */
  private get AgreementId(): number {
    return Number(this.$route.params.id);
  }

  /** パンくずリスト */
  private breadItems: BreadItem[] = [
    {
      text: "契約情報",
      disabled: false,
      to: "/ibow2/contract/info",
      exact: true
    },
    {
      text: "法人情報変更申請",
      disabled: true,
      to: ""
    }
  ];

  mounted(): void {
    this.fetch();
    this.fetchBefore();
  }

  /** データ更新(変更前) */
  private fetchBefore(): void {
    this.postJsonCheck(
      window.base_url + "/api/agreement/get",
      {
        agreement_id: this.AgreementId
      },
      res => {
        this.agreement_info_before = res.data.agreement_info;
      }
    );
  }

  /** データ更新 */
  private fetch(): void {
    this.postJsonCheck(
      window.base_url + "/api/agreement/get",
      {
        agreement_id: this.AgreementId
      },
      res => {
        this.agreement_info = res.data.agreement_info;
        this.agreement = res.data.agreement;
      }
    );
  }

  /** 申請 */
  private async clickConfirm() {
    // 備考以外を比較
    const beforeData = JSON.stringify(
      Object.entries(this.agreement_info_before).filter(
        data => data[0] != "remarks"
      ) // 備考除外
    );
    const changeData = JSON.stringify(
      Object.entries(this.agreement_info).filter(data => data[0] != "remarks") // 備考除外
    );

    // 変更の差異を確認
    if (beforeData !== changeData) {
      if (
        await this.$openConfirm(
          "上記内容で変更を申請します。よろしいですか？\n（承認にはお時間がかかる場合がございます。）"
        )
      ) {
        this.postJsonCheck(
          window.base_url + "/api/agreement/confirm",
          {
            agreement_info: this.agreement_info,
            agreement: this.agreement
          },
          () => {
            this.goBack();
          }
        );
        return;
      }
    } else {
      // 変更なし（または備考のみ）
      if (
        await this.$openConfirm(
          "法人情報に変更がないため、前画面に戻ります。\n（備考のみでは申請できません）"
        )
      ) {
        this.goBack();
      } else {
        return;
      }
    }
  }

  /** 戻る */
  private goBack() {
    if (window.history.length > 0) {
      this.$router.back();
    } else {
      this.$router.replace({ path: "/ibow2/contract/info" });
    }
  }
}
