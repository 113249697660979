









































import { Component, Emit, Mixins } from "vue-property-decorator";
import LoginMixin from "#/mixins/loginMixin";
import AxiosMixin from "@/mixins/axiosMixin";

@Component({})
export default class extends Mixins(LoginMixin, AxiosMixin) {
  /** 生体認証登録終了 */
  @Emit() private registed() {
    return;
  }

  /** ダイアログ展開状態 */
  private dialog = false;

  /** 次回から表示しないのチェック */
  private isCheck = false;

  /** 次回から表示しないのチェック表示フラグ */
  private isDisplayCheckbox = true;

  /** ダイアログを開く */
  public open(isDisplayCheckbox = true) {
    this.isDisplayCheckbox = isDisplayCheckbox;
    this.isCheck = false;
    this.dialog = true;
  }

  /** ダイアログ閉じる */
  public close() {
    if (this.isCheck) {
      localStorage.setItem("not-need-biometric-auth-regist", "1");
    }
    this.dialog = false;
  }

  /** 生体認証登録処理 */
  private regist() {
    this.registerWebauthnBegin(
      window.base_url,
      this.postJsonCheck,
      this.$openAlert,
      () => {
        this.registed();
        this.dialog = false;
      },
      () => {
        this.dialog = false;
      }
    );
  }
}
