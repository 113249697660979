




























































































































































































import { Component, Vue, Prop, Emit, Watch, Ref } from "vue-property-decorator";
import { CalendarEventParsed, CalendarTimestamp } from "vuetify";
import * as appDate from "../utility/appDate";
import { Calendar } from "./calendar/common";
import AppToolTip from "./AppToolTip.vue";
import { CalendarDispEventMoving as CalendarDispEvent } from "#/components/calendar/common";

@Component({
  components: {
    AppToolTip
  }
})
export default class AppCalendar extends Vue {
  // カレンダーコンポーネント
  @Ref("calendar") private readonly calendar!: Calendar;

  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** カレンダーの高さ */
  @Prop({ default: "auto" }) height!: string;

  /** カレンダーの最小の高さ */
  @Prop({ default: "660px" }) minHeight!: string;

  /** カレンダーの幅 */
  @Prop({ default: "" }) width!: string;

  /** 誕生日 */
  @Prop() birthday!: string;

  /** カレンダーイベント */
  @Prop() events!: CalendarDispEvent[];

  /** カレンダータイプ 日・週・月 */
  @Prop({ default: "month" }) type!: string;

  /** 表示基準日 */
  @Prop({ default: "" }) value!: string;

  /** 参加状況表示フラグ */
  @Prop({ default: false }) isStatusDisp!: boolean;

  /** カレンダーアイコン表示フラグ */
  @Prop({ default: false }) isIcon!: boolean;

  /** カレンダーアイコン表示フラグ */
  @Prop({ default: 80 }) intervalHeight!: number;

  /** カレンダーイベントの表示内容の処理 */
  @Prop({
    default: (event: CalendarDispEvent) => event.name,
    type: Function
  })
  dispEvent!: (
    event: CalendarDispEvent,
    eventParsed: CalendarEventParsed,
    timed: CalendarTimestamp
  ) => string;

  /** カレンダーイベントのツールチップの表示内容の処理 */
  @Prop({
    default: (event: CalendarDispEvent) => event.name,
    type: Function
  })
  dispTooltipEvent!: (
    event: CalendarDispEvent,
    eventParsed: CalendarEventParsed,
    timed: CalendarTimestamp
  ) => string;

  /** カレンダーイベントのクラスの処理 */
  @Prop({ default: () => "", type: Function }) eventClass!: (
    event: CalendarDispEvent,
    eventParsed: CalendarEventParsed,
    timed: CalendarTimestamp
  ) => string;

  /** カレンダースタイルのクラスの処理 */
  @Prop({ default: () => "", type: Function }) eventStyle!: (
    event: CalendarDispEvent
  ) => string;

  /** 参加予定 1：斜線 2：塗りつぶし*/
  @Prop({ default: () => 0, type: Function }) checkStatus!: (
    event: CalendarDispEvent
  ) => number;

  /** キャンセル状態 */
  @Prop({ default: -1 }) cancelStatus!: number;

  /** 未定状態 */
  @Prop({ default: 1 }) notYetStatus!: number;

  /** 参加状態 */
  @Prop({ default: 2 }) attendStatus!: number;

  /** 不参加状態 */
  @Prop({ default: -1 }) notAttendStatus!: number;

  /** カレンダーアイコン*/
  @Prop({ default: () => 0, type: Function }) isCalendarEvent!: (
    event: CalendarDispEvent
  ) => boolean;

  @Prop({ default: () => 0, type: Function }) isVisitEvent!: (
    event: CalendarDispEvent
  ) => boolean;

  @Prop({ default: () => 0, type: Function }) isShiftEvent!: (
    event: CalendarDispEvent
  ) => boolean;
  @Prop({ default: () => 0, type: Function }) isIndividualEvent!: (
    event: CalendarDispEvent
  ) => boolean;

  /** カレンダーイベントのマージン */
  @Prop({ default: 1 }) eventMarginBottom!: number;

  /** カテゴリー */
  @Prop() categories!: string[];

  /** インターバル */
  @Prop({ default: 24 }) intervalCount!: number;
  @Prop({ default: 0 }) firstInterval!: number;

  /** カテゴリを全て表示するかどうか */
  @Prop({ default: false }) categoryShowAll!: boolean;

  /** カレンダータイプの変更 */
  @Emit("update:type") updateType(newValue: string) {
    return newValue;
  }

  /** 表示基準日の変更 */
  @Emit() input(newValue: string) {
    return newValue;
  }

  /** カレンダーイベントをクリックした時の処理 */
  @Emit()
  private handleEvent(event: CalendarDispEvent) {
    return event;
  }

  /** カレンダーイベントを移動した時の処理 */
  @Emit()
  private moveEvent(
    event: CalendarDispEvent,
    orgEvent: CalendarDispEvent | null
  ) {
    return { event: event, orgEvent: orgEvent };
  }

  /** カレンダーイベントの時間拡張をした時の処理 */
  @Emit()
  private extendTimeEvent(
    event: CalendarDispEvent,
    orgEvent: CalendarDispEvent | null
  ) {
    return { event: event, orgEvent: orgEvent };
  }

  /** イベント以外のカレンダーをクリックした時の処理 */
  @Emit()
  private clickCalendar(event: CalendarDispEvent) {
    return event;
  }

  /** カレンダータイプの変更時に、現在時刻の線表示位置を変更する */
  @Watch("type", { immediate: true })
  private changeDocumentType() {
    this.$nextTick(() => {
      if (this.calendar && this.calendar.scrollToTime) {
        if (this.type === "category") {
          // categoryだとscrollToTopが動かないバグ対策
          this.calendar.$el.scrollTop = this.calendar.timeToY(
            this.calendar.times.now
          );
        } else {
          this.calendar.scrollToTime(`${this.calendar.times.now.hour - 2}:00`);
        }
        this.nowY = this.calendar.timeToY(this.calendar.times.now) + "px";
      }
    });
  }
  @Watch("firstInterval")
  private changeFirstInterval() {
    this.changeDocumentType();
  }

  /** 月表示の日をクリックした時の処理 */
  @Emit() private clickMonthDay(event: unknown) {
    return event;
  }

  /** カレンダー現在時刻の線表示位置のY座標の位置 */
  private nowY = "-10px";

  /** カレンダーのタイトル */
  public get title() {
    return this.calendar.title;
  }

  private get Type(): string {
    return this.type;
  }

  private set Type(newValue: string) {
    this.updateType(newValue);
  }

  private get Value(): string {
    return this.value;
  }

  private set Value(newValue: string) {
    this.input(newValue);
  }

  /** 日表示の時、特別につけるクラス */
  private get TargetDayClass() {
    if (this.Type != "day" && this.Type != "category") {
      return "";
    }
    if (!this.Value) {
      return "";
    }
    let date = new Date();
    if (typeof this.Value === "string") {
      date = new Date(this.Value.replace(/-/g, "/"));
    } else if (typeof this.Value === "object") {
      date = this.Value as Date;
    }
    switch (date.getDay()) {
      case 0:
        return "sunday";
      case 6:
        return "saturday";
    }
    return "";
  }

  /** 前へ */
  public prev() {
    return this.calendar.prev();
  }

  /** 次へ */
  public next() {
    return this.calendar.next();
  }

  public scrollToTime(time: string) {
    this.calendar.scrollToTime(time);
  }

  /** カレンダーの日をクリックをした時、月表示だったら、週表示にする */
  private clickDay(date: string) {
    this.Value = date;
    if (this.Type === "month") {
      this.Type = "week";
    }
  }

  /** カレンダーの日を表示用日付にする */
  private dispDate(date: string): string {
    let dispDate = "";
    if (date.slice(8) == "01") {
      dispDate = date.slice(5).replace(/-/g, "/");
    } else {
      dispDate = date.slice(8).replace(/-/g, "/");
    }
    return dispDate;
  }

  // 現在時刻ボーダー表示フラグ
  private showCurrentBordar(date: string, week: { date: string }[]): boolean {
    const weekDates = week.map(w => w.date);
    const currentDate = appDate.dateToStr(new Date(), "yyyy-MM-dd");
    return weekDates.findIndex(date => date === currentDate) > -1;
  }

  // ツールチップの内容表示
  private innerDispTooltipEvent(
    event: CalendarDispEvent,
    eventParsed: CalendarEventParsed,
    timed: CalendarTimestamp
  ): string {
    let text = "";
    if (event.is_repeat) {
      text += "【繰り返し】";
    }
    text += this.dispTooltipEvent(event, eventParsed, timed);
    return text;
  }

  /********************************************************************************************/
  /* ドラッグ操作
  /********************************************************************************************/

  /** ドラッグ中のイベント */
  private draggingEvent: CalendarDispEvent | null = null;

  /** 時間増減のドラッグ中のイベント */
  private timeDraggingEvent: CalendarDispEvent | null = null;

  /** ドラッグ開始時の状態のイベント */
  private orgEvent: CalendarDispEvent | null = null;

  /** ドラッグ開始時の時間 */
  private dragTime: number | null = null;

  /** 時間増減のドラッグ開始時の時間 */
  private timeDragTime: number | null = null;

  /** ドラッグ移動の最低高さ（15分） */
  private roundTo = 15 * 60 * 1000;

  /** イベントドラッグ開始 */
  private startEventDrag(e: { event: CalendarDispEvent; timed: boolean }) {
    this.draggingEvent = e.event;
    this.draggingEvent.tmp_timed = e.timed;
    this.orgEvent = { ...this.draggingEvent };
  }
  /** 時間増減ドラッグ開始 */
  private extendBottom(event: CalendarDispEvent, timed: boolean) {
    this.timeDraggingEvent = event;
    this.timeDraggingEvent.tmp_timed = timed;
    this.orgEvent = { ...this.timeDraggingEvent };
    this.timeDragTime = appDate.strToDate(event.start).getTime();
  }

  /** ドラッグ開始時のイベント（ドラッグ時間を取得する） */
  private startDrag(tms: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
  }) {
    const mouse = this.toTime(tms);
    if (this.draggingEvent && this.dragTime === null) {
      // イベントドラッグ開始
      const start = appDate.strToDate(this.draggingEvent.start);
      this.dragTime = mouse - start.getTime();
    }
  }

  /** ドラッグ中のイベント */
  private moveDrag(tms: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
  }) {
    //月表示の場合は、何もしない
    if (this.Type === "month") {
      return;
    }

    const mouse = this.toTime(tms);

    //イベントドラッグの場合
    if (
      this.draggingEvent &&
      this.dragTime !== null &&
      this.draggingEvent.tmp_timed &&
      !this.draggingEvent.is_not_moving
    ) {
      // イベントの移動
      const start = appDate.strToDate(this.draggingEvent.start).getTime();
      const end = appDate.strToDate(this.draggingEvent.end).getTime();
      const duration = end - start;
      const newStartTime = mouse - this.dragTime;
      const newStart = this.roundTime(newStartTime);
      const newEnd = newStart + duration;
      this.draggingEvent.start = appDate.dateToStr(
        new Date(newStart),
        "yyyy-MM-dd HH:mm"
      );
      this.draggingEvent.end = appDate.dateToStr(
        new Date(newEnd),
        "yyyy-MM-dd HH:mm"
      );
    }

    //時間増減ドラッグの場合
    if (
      this.timeDraggingEvent &&
      this.timeDragTime !== null &&
      !this.timeDraggingEvent.is_not_moving
    ) {
      // イベント時間の伸縮
      const mouseRounded = this.roundTime(mouse, false);
      const min = Math.min(mouseRounded, this.timeDragTime);
      const max = Math.max(mouseRounded, this.timeDragTime);

      this.timeDraggingEvent.start = appDate.dateToStr(
        new Date(min),
        "yyyy-MM-dd HH:mm"
      );
      this.timeDraggingEvent.end = appDate.dateToStr(
        new Date(max),
        "yyyy-MM-dd HH:mm"
      );
    }
  }

  // 月表示のイベントドラッグ
  private moveDayDrag(tms: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
  }) {
    if (this.draggingEvent && !this.draggingEvent.is_not_moving) {
      // 開始日（ドラッグ前）
      const startDate = appDate.strToDate(this.draggingEvent.start).getTime();
      // 終了日（ドラッグ前）
      const endDate = appDate.strToDate(this.draggingEvent.end).getTime();
      // 開始時間（ドラッグ前）
      const startTime = appDate.dateToStr(
        new Date(this.draggingEvent.start.replace(/-/g, "/")),
        "HH:mm"
      );
      // 終了時間（ドラッグ前）
      const endTime = appDate.dateToStr(
        new Date(this.draggingEvent.end.replace(/-/g, "/")),
        "HH:mm"
      );
      const duration = endDate - startDate;
      const mouse = this.toTime(tms);
      const newStart = this.roundTime(mouse);
      const newEnd = newStart + duration;
      this.draggingEvent.start =
        appDate.dateToStr(new Date(newStart), "yyyy-MM-dd") + " " + startTime;
      this.draggingEvent.end =
        appDate.dateToStr(new Date(newEnd), "yyyy-MM-dd") + " " + endTime;
    }
  }

  /** ドラッグ終了時のイベント */
  private endDrag(e: CalendarDispEvent) {
    if (this.draggingEvent) {
      //イベントドラッグの場合

      if (this.isDrag(this.orgEvent, this.draggingEvent)) {
        // ドラッグ操作で変更があった場合、イベント移動処理呼び出し
        this.moveEvent(this.draggingEvent, this.orgEvent);
      } else {
        // ドラッグ操作で変更がなかった場合、イベントクリック処理呼び出し
        this.handleEvent(this.draggingEvent);
      }
    } else if (this.timeDraggingEvent) {
      //時間増減ドラッグの場合

      // イベント時間増減処理呼び出し
      if (this.isDrag(this.orgEvent, this.timeDraggingEvent)) {
        this.extendTimeEvent(this.timeDraggingEvent, this.orgEvent);
      }
    } else {
      //イベント外のクリックの場合

      //カレンダークリック処理呼び出し
      this.clickCalendar(e);
    }
    // ドラッグ変数初期化
    this.cancelDrag();
  }
  // 月表示のイベントドラッグ終了
  private endDayEventDrag() {
    if (this.draggingEvent) {
      //イベントドラッグの場合
      if (this.isDrag(this.orgEvent, this.draggingEvent)) {
        // ドラッグ操作で変更があった場合、イベント移動処理呼び出し
        this.moveEvent(this.draggingEvent, this.orgEvent);
      } else {
        // ドラッグ操作で変更がなかった場合、イベントクリック処理呼び出し
        this.handleEvent(this.draggingEvent);
      }
    }
    this.cancelDrag();
  }
  /** ドラッグキャンセル時のイベント */
  private cancelDrag() {
    this.draggingEvent = null;
    this.dragTime = null;
    this.orgEvent = null;
    this.timeDraggingEvent = null;
    this.timeDragTime = null;
  }

  /** ドラッグ開始時の時間とドラッグ中の時間でドラッグ操作をしたかどうか判定する */
  private isDrag(
    before: { start: string; end: string } | null,
    after: { start: string; end: string } | null
  ): boolean {
    if (before == null || after == null) {
      return false;
    }
    const beforeStart = appDate.strToDate(before.start);
    const afterStart = appDate.strToDate(after.start);
    const beforeEnd = appDate.strToDate(before.end);
    const afterend = appDate.strToDate(after.end);
    const diffStart = Math.abs(beforeStart.getTime() - afterStart.getTime());
    const diffEnd = Math.abs(beforeEnd.getTime() - afterend.getTime());
    if (diffStart >= this.roundTo || diffEnd >= this.roundTo) {
      return true;
    }
    return false;
  }

  /** 時間イベントをDateに変換する */
  private toTime(tms: {
    year: number;
    month: number;
    day: number;
    hour: number;
    minute: number;
  }) {
    return new Date(
      tms.year,
      tms.month - 1,
      tms.day,
      tms.hour,
      tms.minute
    ).getTime();
  }

  /** 15分単位で丸める */
  private roundTime(time: number, down = true): number {
    return down
      ? time - (time % this.roundTo)
      : time + (this.roundTo - (time % this.roundTo));
  }
  /** 月表示のドラッグ ***************************************************************************/

  /********************************************************************************************/
}
