































import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

@Component
export default class extends Mixins(AxiosMixin) {
  /** タイトル文言 */
  @Prop() private readonly title!: string;

  /** テキスト文言 */
  @Prop() private readonly text!: string;

  /** セキュリティコード */
  keyCode = "";

  /** セキュリティコードを確認する */
  private changeEmail() {
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/check",
      {
        token: this.$route.query.token,
        key_code: this.keyCode
      },
      () => {
        this.$router.replace("/userinfo");
      }
    );
  }
}
