


























































































































import { Component, Prop, Emit, Ref, Mixins } from "vue-property-decorator";
import { Document } from "@/components/calendar/types";
import AxiosMixin from "@/mixins/axiosMixin";
import LayerDialog from "@/components/calendar/myCalendar/myCalendarFilterButton/LayerDialog.vue";
import Draggable from "vuedraggable";
import { Layer, DefaultLayer } from "#/components/calendar/common";

@Component({
  components: {
    LayerDialog,
    Draggable
  }
})
export default class MyCalendarFilterButton extends Mixins(AxiosMixin) {
  /** カレンダーレイヤー編集ダイアログrefオブジェクト */
  @Ref("layer-dialog") private readonly layerDialog!: LayerDialog;

  /** レイヤー選択状態 （fire store 保持） */
  @Prop({ default: () => ({}) }) document!: Document;

  /** 全てのカレンダーレイヤー */
  @Prop({ default: () => [] }) layers!: Layer[];

  /** マイページカレンダーレイヤー */
  @Prop({ default: () => [] }) mypage_layers!: Layer[];

  /** データ更新が必要な時 */
  @Emit() update() {
    return;
  }

  /** レイヤー変更時（ドラッグ操作） */
  @Emit("update:layers") updateLayers(layers: Layer[]) {
    return layers;
  }

  /** 検索サイドバー開閉状態 */
  private isOpenSearchPanel = false;

  /** レイヤー編集ダイアログ開閉状態 */
  private isOpenLayerDialog = false;

  /** カレンダーレイヤー操作選択肢リスト */
  private calendarChoices = [
    {
      text: "編集",
      func: this.add
    },
    {
      text: "削除",
      func: this.del
    }
  ];

  /** カレンダーレイヤーと対応するドキュメントリスト */
  private get DocumentLayers() {
    const layerDocuments: {
      layer: Layer;
      selectLayer: {
        layerId: number;
        checked: boolean;
      };
    }[] = [];
    for (const layer of this.layers) {
      const selectLayer = this.selectLayer(layer.id);
      layerDocuments.push({
        layer,
        selectLayer
      });
    }
    return layerDocuments;
  }
  private set DocumentLayers(documentLayers) {
    const layers = [];
    for (const documentLayer of documentLayers) {
      layers.push(documentLayer.layer);
    }
    this.updateLayers(layers);
  }

  // カレンダーレイヤーが削除可能かどうか
  private get IsDeleteLayerEvent(): boolean {
    // 編集可能なレイヤーが２件以上存在しない場合は削除不可
    if (!this.mypage_layers || this.mypage_layers.length < 2) {
      return false;
    }
    return true;
  }

  /** 検索サイドバーの開閉状態を切り替える */
  toggleFilter() {
    this.isOpenSearchPanel = !this.isOpenSearchPanel;
  }

  /** 全てを選択状態にする */
  private checkAll() {
    this.document.selectLayers.forEach(layer => {
      layer.checked = true;
    });
    this.update();
  }

  /** 全てを選択状態にする */
  private uncheckAll() {
    this.document.selectLayers.forEach(layer => {
      layer.checked = false;
    });
    this.update();
  }

  /** カレンダーレイヤーを追加・編集する */
  private add(layer: Layer = DefaultLayer("#F44336FF")) {
    this.layerDialog.open(layer);
  }

  /** カレンダーレイヤーを削除する */
  private async del(layer: Layer) {
    // 削除可能判定
    if (!this.IsDeleteLayerEvent) {
      await this.$openAlert(
        "編集可能なカレンダーが2件以上登録されていない場合は削除できません"
      );
      return;
    }
    if (
      await this.$openConfirm(
        `${layer.name}を削除します。\n${layer.name}で登録されているイベントも削除されますが、よろしいですか？`
      )
    ) {
      this.postJsonCheck(
        window.base_url + "/api/mycalendar/layer/delete",
        { layer: layer },
        () => {
          this.update();
        }
      );
    }
  }

  /** カレンダーレイヤーの並び替え状態をfirestoreに保存する */
  private sortCalendarLayer() {
    const newSelectLayers: { layerId: number; checked: boolean }[] = [];
    for (const layer of this.layers) {
      const selectLayer = this.selectLayer(layer.id);
      newSelectLayers.push(selectLayer);
    }
    this.document.selectLayers = newSelectLayers;
  }

  /** レイヤーを取得する */
  private selectLayer(
    layerId: number
  ): {
    layerId: number;
    checked: boolean;
  } {
    const s = this.document.selectLayers.find(
      layer => layer.layerId == layerId
    );
    if (s) {
      return s;
    }
    this.document.selectLayers.push({
      layerId: layerId,
      checked: true
    });
    return this.document.selectLayers[this.document.selectLayers.length - 1];
  }
}
