






















































import { Component, Emit, Mixins, Ref } from "vue-property-decorator";
import Cookies from "js-cookie";
import { AxiosResponse } from "axios";
import RulesMixin from "#/mixins/rulesMixin";
import LoginMixin from "#/mixins/loginMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import BiometricRegistDialog from "@/components/biometric/BiometricRegistDialog.vue";

@Component({ components: { BiometricRegistDialog } })
export default class DoubleLoginArea extends Mixins(
  RulesMixin,
  AxiosMixin,
  LoginMixin
) {
  @Ref("BiometricRegistDialog")
  private readonly biometricRegistDialog!: BiometricRegistDialog;

  /** ログイン完了処理 */
  @Emit() private finishLogin(isOpenRegistDialog = false) {
    return isOpenRegistDialog;
  }

  /** セキュリティコード確認 */
  private doubleLogin() {
    this.loginKeyCode(
      window.base_url,
      window.axios,
      this.$route.query.security_code as string,
      this.setCookie,
      this.$openAlert
    );
  }

  /** 生体認証 */
  private webauthnLogin() {
    this.loginWebauthnBegin(
      window.base_url,
      window.axios,
      this.$route.query.security_code as string,
      this.setCookie,
      this.$openAlert
    );
  }

  /** クッキーに設定 */
  private async setCookie(res: AxiosResponse) {
    window.token = res.data.token;
    Cookies.set("token", res.data.token, { domain: window.cookie_domain });
    window.reftoken = res.data.ref_token;
    Cookies.set("reftoken", res.data.ref_token, {
      domain: window.cookie_domain
    });
    if (res.data.not_webauthn_device_registerd) {
      this.finishLogin(true);
    } else {
      this.finishLogin(false);
    }
  }
}
