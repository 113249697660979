



























import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { PortalAccount } from "@/types/mypage";
import Staff from "./portalofficelist/Staff.vue";

@Component({ components: { Staff } })
export default class extends Mixins(AxiosMixin) {
  /** ポータル職員一覧 */
  @Prop({ default: () => [] }) private portal_staffs!: PortalAccount[];

  /** ポータル紹介者一覧 */
  @Prop({ default: () => [] }) private portal_request_users!: PortalAccount[];
}
