














import { Component, Vue } from "vue-property-decorator";
import Title from "@/components/common/Title.vue";

@Component({ components: { Title } })
export default class extends Vue {}
