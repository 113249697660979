


















import { Vue, Component, Emit, Prop } from "vue-property-decorator";

// トグルボタン
@Component
export default class AppToggleButton extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;

  // 選択肢
  @Prop({ default: () => [""] })
  options!: Array<string>;
  // 全て未選択許容
  @Prop({ default: false }) required!: boolean;
  // 値
  @Prop({ default: "0" }) value!: string;
  // ON時カラー
  @Prop({ default: "primary" }) onColor!: string;
  // OFF時カラー
  @Prop({ default: "" }) offColor!: string;

  /*
   * emit
   */
  @Emit()
  private input(val: string) {
    return val;
  }

  /*
   * method
   */

  // カラー
  private color(idx: number): string {
    const val = this.getValue(idx);
    if (val == 0) {
      return this.offColor;
    } else {
      return this.onColor;
    }
  }

  private getValue(idx: number): number {
    return Number(this.value[idx]);
  }
  private setValue(idx: number) {
    const values = this.value.split("");
    if (values[idx] == "0") {
      values[idx] = "1";
    } else {
      values[idx] = "0";
    }
    if (this.required) {
      // 一つでも選択されていれば曜日をセット（曜日未入力にさせない）
      for (const value of values) {
        if (value == "1") {
          this.input(values.join(""));
          break;
        }
      }
    } else {
      this.input(values.join(""));
    }
  }
}
