




















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import PasswordRemind from "@/components/app/password/PasswordRemind.vue";
import PasswordRemindCheckCode from "@/components/app/password/PasswordRemindCheckCode.vue";
import PasswordRemindChange from "@/components/app/password/PasswordRemindChange.vue";

@Component({
  components: { PasswordRemind, PasswordRemindCheckCode, PasswordRemindChange }
})
export default class extends Vue {
  /** パスワード画面フラグ */
  @Prop({ default: 0 }) private value!: number;

  /** パスワード画面フラグ変更 */
  @Emit() private input(newValue: number) {
    return newValue;
  }

  /** パスワード画面フラグ */
  private get PasswordResetType() {
    return this.value;
  }
  private set PasswordResetType(newValue: number) {
    this.input(newValue);
  }

  //パスワードリセットセキュリティコードチェック画面へ
  private checkPasswordSecurity() {
    this.PasswordResetType = 2;
  }

  //パスワード再設定画面へ
  private inputPasswordReset() {
    this.PasswordResetType = 3;
  }

  //ログイン画面へ
  private toLogin() {
    this.PasswordResetType = 0;
  }
}
