

































import {
  CalendarDispEventMoving as CalendarDispEvent,
  EditRangeDiv,
  Layer
} from "#/components/calendar/common";
import { Component, Ref, Prop, Emit, Mixins } from "vue-property-decorator";
import * as appDate from "#/utility/appDate";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import AppCalendarV2 from "#/components/AppCalendarV2.vue";
import ShowEventDialog from "@/components/calendar/myCalendar/mypageCalendar/ShowEventDialog.vue";
import EditEventDialog from "@/components/calendar/myCalendar/mypageCalendar/EditEventDialog.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component({
  components: {
    AppCalendarV2,
    ShowEventDialog,
    EditEventDialog
  }
})
export default class MypageCalendar extends Mixins(UtilMixin, AxiosMixin) {
  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: AppCalendarV2;

  /** 表示ダイアログ */
  @Ref("show-event")
  private readonly showEventDialog!: ShowEventDialog;

  /** 編集ダイアログ */
  @Ref("edit-event")
  public readonly editEventDialog!: EditEventDialog;

  /** 表示基準日 */
  @Prop({ default: "" }) value!: string;

  /** カレンダータイプ 日・週・月 */
  @Prop({ default: "month" }) type!: string;

  /** カレンダーイベント */
  @Prop({ default: () => [] }) events!: CalendarDispEvent[];

  /** カレンダーの高さ */
  @Prop({ default: "auto" }) height!: string;

  /** カレンダークラス */
  @Prop({ default: "" }) calendarClass!: string;

  /** 誕生日 */
  @Prop() birthday!: string;

  /** 時間毎の間隔 */
  @Prop({ default: 80 }) intervalHeight!: number;

  /** 表示時間数 */
  @Prop({ default: 24 }) intervalCount!: number;

  /** 一番上の表示時間 */
  @Prop({ default: 0 }) firstInterval!: number;

  /** クリックできるイベントタイプ */
  @Prop({ default: () => [] }) clickTypes!: number[];

  /** 移動できるイベントタイプ */
  @Prop({ default: () => [] }) moveTypes!: number[];

  /** マイページレイヤー */
  @Prop({ default: () => [] }) myPagelayers!: Layer[];

  /** その他カレンダーイベントをクリックした時の処理 */
  @Emit()
  private otherHandleEvent(event: CalendarDispEvent) {
    return event;
  }

  /** その他カレンダーイベントを移動した時の処理 */
  @Emit()
  private otherMoveEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent | null;
  }) {
    return e;
  }

  /** 表示基準日の変更 */
  @Emit() input(newValue: string) {
    return newValue;
  }

  /** カレンダータイプの変更 */
  @Emit("update:type") updateType(newValue: string) {
    return newValue;
  }

  /** カレンダーをクリックする */
  @Emit() clickCalendar(e: CalendarDispEvent) {
    return e;
  }

  /** データ更新 */
  @Emit() update() {
    return;
  }

  /** イベント */
  private get Events(): CalendarDispEvent[] {
    for (const event of this.events) {
      if (this.moveTypes.indexOf(event.type) === -1) {
        event.is_not_moving = true;
        continue;
      }
      if (event.status === 1) {
        event.is_not_moving = true;
        continue;
      }
    }
    return this.events;
  }

  /** 表示基準日 */
  private get Value(): string {
    return this.value;
  }
  private set Value(newValue: string) {
    this.input(newValue);
  }

  /** カレンダータイプ 日・週・月 */
  private get Type(): string {
    return this.type;
  }
  private set Type(newType: string) {
    this.updateType(newType);
  }

  /** カレンダーのタイトル */
  public get title() {
    return this.calendar.title;
  }

  /** 前へ */
  public prev() {
    return this.calendar.prev();
  }

  /** 次へ */
  public next() {
    return this.calendar.next();
  }

  /** スクロール位置を戻す */
  public scrollToTime(time: string) {
    this.calendar.scrollToTime(time);
  }

  /** イベントをクリックした時 */
  private clickEvent(event: CalendarDispEvent) {
    if (this.clickTypes.indexOf(event.type) === -1) {
      return;
    }

    switch (event.type) {
      case EventType.AccountSchedule: //アカウント予定
        this.clickAccountEvent(event);
        break;
      default:
        this.otherHandleEvent(event);
        break;
    }
  }

  /** イベントを移動した時 */
  private moveEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent;
  }) {
    if (this.moveTypes.indexOf(e.event.type) === -1) {
      return;
    }

    switch (e.event.type) {
      case EventType.AccountSchedule: //アカウント予定
        this.moveAccountEvent(e);
        break;
      default:
        this.otherMoveEvent(e);
        break;
    }
  }

  /** マイページの予定をクリックした時 */
  private clickAccountEvent(event: CalendarDispEvent) {
    this.showEventDialog.open(event);
  }

  /** マイページの予定を移動した時 */
  private moveAccountEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent;
  }) {
    this.postJsonCheck(
      window.base_url + "/api/mycalendar/event/simplesave",
      {
        event_id: e.event.event_id,
        start: e.event.start,
        end: e.event.end,
        edit_start: e.orgEvent.start
      },
      () => {
        this.update();
      },
      () => {
        e.event.start = e.orgEvent.start;
        e.event.end = e.orgEvent.end;
      }
    );
  }

  /** マイページの予定を編集する */
  private editScheduleEvent(req: {
    event: CalendarDispEvent;
    editRange: EditRangeDiv;
  }) {
    this.editEventDialog.open(
      req.event.event_id,
      appDate.dateToStr(appDate.strToDateObj(req.event.start), "yyyy-MM-dd"),
      0,
      0,
      req.editRange
    );
  }
}
