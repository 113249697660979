









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class AppTitleHeader extends Vue {
  @Prop({ default: "" }) private title!: string;
}
