






















import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import FileUpload, { FileIF } from "#/components/FileUpload.vue";
import { Ewelluser } from "@/types/ewelluser";

@Component({ components: { FileUpload } })
export default class extends Vue {
  /** iBowアカウント情報 */
  @Prop() private readonly user!: Ewelluser;

  @Watch("user", { immediate: true }) private watchUser() {
    this.profileItem = {
      id: 0,
      path: this.user.icon_path,
      comment: ""
    };
  }

  @Watch("profileItem", { deep: true }) private watchProfileItem() {
    this.user.icon_path = this.profileItem.path;
  }

  /** アップロードURL */
  private uploadApiUrl = window.base_url + "/api/upload";

  /** プレビューURL */
  private previewApiUrl = window.base_url + "/api/preview";

  /** プロフィール画像アイテム */
  private profileItem: FileIF = {
    id: 0,
    path: "",
    comment: ""
  };

  /** 画像アップロードパス */
  private get ImagePath() {
    return "mypage/" + this.user.id + "/user_info/";
  }

  /** 画像削除ボタンクリック */
  private async clickDeleteFile() {
    if (await this.$openConfirm("削除します。よろしいですか？")) {
      this.user.icon_path = "";
    }
  }
}
