import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "@/store/types";
import { master } from "@/store/modules/master";
import { mutations } from "@/store/mutations";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: "0.0.1",
    is_edit: false,
    is_registerd_auth_navigation: false,
    is_visit_navigation: false,
    is_save: false,
    is_btn_click: false,
    is_processing: false
  },
  mutations,
  modules: {
    master
  }
};

export default new Vuex.Store<RootState>(store);
