






















































































































































































































































import {
  Component,
  Mixins,
  Prop,
  Ref,
  Emit,
  Watch
} from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import * as appDate from "#/utility/appDate";
import {
  CalendarEvent,
  DefaultCalendarEvent,
  DefaultCalendarParticipant
} from "#/model/schedule/calendarEvent";
import {
  RepeatDiv,
  EditRangeDiv,
  RepeatPeriodDiv,
  WeekDays,
  RepeatItems,
  EditRange,
  getEventWeekDays,
  getEventWeeks,
  getEventWeekTimes,
  Layer
} from "#/components/calendar/common";
import RepeateLimitArea from "#/components/calendar/RepeateLimitArea.vue";
import RepeatEveryWeek from "#/components/calendar/RepeatEveryWeek.vue";
import RepeatMonthlyDateAssign from "#/components/calendar/RepeatMonthlyDateAssign.vue";
import RepeatMonthlyWeekAssign from "#/components/calendar/RepeatMonthlyWeekAssign.vue";
import RepeatMonthlyWeekDayAssign from "#/components/calendar/RepeatMonthlyWeekDayAssign.vue";
import { VForm } from "#/components/FileUpload.vue";

@Component({
  components: {
    RepeateLimitArea,
    RepeatEveryWeek,
    RepeatMonthlyDateAssign,
    RepeatMonthlyWeekAssign,
    RepeatMonthlyWeekDayAssign
  }
})
export default class EditEventDialog extends Mixins(
  UtilMixin,
  AxiosMixin,
  RulesMixin
) {
  /** 入力フォーム */
  @Ref("form") private readonly form!: VForm;

  /** カレンダーレイヤー */
  @Prop() private readonly myPagelayers!: Layer[];

  /** データ更新時 */
  @Emit() private update(): CalendarEvent {
    return this.event;
  }

  /** 繰り返し設定変更時 */
  @Watch("event.repeat_div") private changeRepeatDiv() {
    // 繰り返し設定ありで期限開始日未設定の場合、編集日をセット
    if (
      this.event.repeat_div != RepeatDiv.None &&
      this.event.limit_start_date == ""
    ) {
      this.event.limit_start_date = this.editDate;
    }

    // 繰り返し設定ありで各設定値が未設定の場合初期値をセット
    const limitStart = appDate.strToDate(this.event.limit_start_date);
    switch (this.event.repeat_div) {
      case RepeatDiv.EveryDay: // 毎日
        break;
      case RepeatDiv.EveryWeek: // 毎週
        this.event.week_days = getEventWeekDays(
          this.event.week_days,
          limitStart
        );
        break;
      case RepeatDiv.EveryMonthSpecifiedDate: // 毎月（日付指定）
        if (!this.event.month_day) {
          this.event.month_day = limitStart.getDate();
        }
        break;
      case RepeatDiv.EveryMonthSpecifiedWeek: // 毎月（週指定）
        this.event.weeks = getEventWeeks(this.event.weeks, limitStart);
        this.event.week_days = getEventWeekDays(
          this.event.week_days,
          limitStart
        );
        break;
      case RepeatDiv.EveryMonthSpecifiedWeekDay: // 毎月（曜日指定）
        this.event.week_times = getEventWeekTimes(
          this.event.week_times,
          limitStart
        );
        this.event.week_days = getEventWeekDays(
          this.event.week_days,
          limitStart
        );
        break;
    }
  }

  /** 終日変更時 */
  @Watch("event.all_day")
  private onChangeAllDay() {
    if (this.event.all_day !== 1) {
      return;
    }
    this.event.start_hh =
      String(this.event.start_hh) === "" ? 0 : this.event.start_hh;
    this.event.start_mm =
      String(this.event.start_mm) === "" ? 0 : this.event.start_mm;
    this.event.end_hh =
      String(this.event.end_hh) === "" ? 0 : this.event.end_hh;
    this.event.end_mm =
      String(this.event.end_mm) === "" ? 0 : this.event.end_mm;
  }

  /** ダイアログ展開状態 */ private dialog = false;

  /** 編集範囲選択値  */ private selectedEditRange = 0;

  /** イベント */ private event = DefaultCalendarEvent();

  /** 編集日付 */ private editDate = "";

  /** 分選択肢 */ private minites = ["00", "10", "20", "30", "40", "50"];

  /** 選択中の繰り返し設定 */ private readonly RepeatDiv = RepeatDiv;

  /** 繰り返し設定一覧  */ private readonly RepeatItems = RepeatItems;

  /** 選択中の編集範囲 */ private readonly EditRangeDiv = EditRangeDiv;

  /** 編集範囲一覧  */ private readonly EditRange = EditRange;

  /** 繰り返しが終了日or回数 */ private readonly RepeatPeriodDiv = RepeatPeriodDiv;

  /** イベント日時（表示用） */
  private get DispDate(): string {
    let d = this.editDate;
    if (this.event.repeat_div == RepeatDiv.None) {
      d = this.event.start_date;
    }
    const date = appDate.strToDate(d);
    return appDate.jpDate(date);
  }

  /** 繰り返し設定（表示用） */
  private get RepeatSetting(): string {
    // 繰り返し区分
    let repeatDiv = "";
    switch (this.event.repeat_div) {
      case RepeatDiv.None:
        repeatDiv = "なし";
        break;
      case RepeatDiv.EveryDay:
        repeatDiv = "毎日";
        break;
      case RepeatDiv.EveryWeek:
        repeatDiv = `毎週（${this.strWeekdays()}）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedDate:
        repeatDiv = `毎月（${this.event.month_day}日）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedWeek:
        repeatDiv = `毎月（${this.strWeekNumbers()} ${this.strWeekdays()}）`;
        break;
      case RepeatDiv.EveryMonthSpecifiedWeekDay:
        repeatDiv = `毎月（${this.strWeekCounts()}  ${this.strWeekdays()}）`;
        break;
    }

    // 時間
    let time = "";
    if (this.event.all_day) {
      time = "終日";
    } else {
      time = `${this.zeroPadding(this.event.start_hh)}:
              ${this.zeroPadding(this.event.start_mm)}〜
              ${this.zeroPadding(this.event.end_hh)}:
              ${this.zeroPadding(this.event.end_mm)}
            `;
    }

    // 期限
    let limit = "";
    if (this.event.repeat_div !== RepeatDiv.None) {
      if (this.selectedEditRange === EditRangeDiv.After) {
        limit = this.editDate.replaceAll("-", "/") + "〜";
      } else {
        limit = this.event.limit_start_date.replaceAll("-", "/") + "〜";
      }
      switch (this.event.limit_div) {
        case RepeatPeriodDiv.Date:
          limit += this.event.limit_end_date.replaceAll("-", "/");
          break;
        case RepeatPeriodDiv.Count:
          limit += this.event.limit_count + "回";
          break;
      }
    }

    return `${repeatDiv} ${time} ${limit}`;
  }

  /** 場所がURLであるかどうか */ private get LocateIcon(): string {
    if (
      this.event.locate.indexOf("http://") === 0 ||
      this.event.locate.indexOf("https://") === 0
    ) {
      return "mdi-map-marker";
    }
    return "";
  }

  /** ダイアログを開く */
  public open(
    eventId: number,
    editDate: string,
    defaultHH = 0,
    defaultMM = 0,
    editRange = EditRangeDiv.New
  ): void {
    if (eventId > 0) {
      this.selectedEditRange = editRange;
      // イベント詳細取得
      this.fetch(eventId);
    } else {
      // 初期値セット
      const minute = defaultMM - (defaultMM % 30);
      this.event = DefaultCalendarEvent();
      this.event.layer_id = this.myPagelayers[0] ? this.myPagelayers[0].id : 0;
      this.event.start_date = editDate;
      this.event.start_hh = defaultHH;
      this.event.start_mm = minute;
      const endDate = appDate.strToDate(editDate);
      endDate.setHours(defaultHH);
      endDate.setMinutes(minute + 60);
      this.event.end_date = appDate.dateToStr(endDate, "yyyy-MM-dd");
      this.event.end_hh = endDate.getHours();
      this.event.end_mm = endDate.getMinutes();
      this.event.participants = [DefaultCalendarParticipant()];
      this.selectedEditRange = EditRangeDiv.New;
    }
    if (this.form) {
      // バリデーションリセット
      this.form.resetValidation();
    }
    // 編集日付セット
    this.editDate = editDate;
    this.dialog = true;
  }

  /** ダイアログを閉じる */
  public close(): void {
    this.dialog = false;
  }

  /** イベントの開始日を変更する */
  private changeStartDate() {
    // 終了日 < 開始日の場合
    if (this.event.end_date && this.event.end_date < this.event.start_date) {
      // 終了日に開始日をセット
      this.event.end_date = this.event.start_date;
    }
  }

  /** イベントを取得する */
  private fetch(eventId: number) {
    this.postJsonCheck(
      window.base_url + "/api/mycalendar/event/get",
      {
        event_id: eventId
      },
      res => {
        if (res.data.event) {
          this.event = res.data.event;
          this.addParticipant();
          if (this.event.repeat_div == RepeatDiv.None) {
            this.selectedEditRange = EditRangeDiv.New;
          }
        }
        if (
          this.event.repeat_div &&
          this.selectedEditRange === EditRangeDiv.This
        ) {
          this.event.start_date = this.editDate;
          this.event.end_date = this.editDate;
        }
      }
    );
  }

  /** 予定を保存する */
  private async save() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります。");
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/mycalendar/event/save",
      {
        event: this.event,
        edit_range: this.selectedEditRange,
        edit_date: this.editDate
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** 予定を削除する */
  private async del() {
    if (!(await this.$openConfirm("削除します。よろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/mycalendar/event/delete",
      {
        event: this.event,
        edit_range: this.selectedEditRange,
        edit_date: this.editDate
      },
      () => {
        this.update();
        this.close();
      }
    );
  }

  /** 参加者のフォーカスを外れた時に、領域を減らしたり、追加したりする */
  private blurParticipant(index: number) {
    if (
      !this.event.participants[index].name &&
      this.event.participants.length > 1
    ) {
      this.event.participants.splice(index, 1);
    }
    if (this.event.participants[this.event.participants.length - 1].name) {
      this.addParticipant();
    }
  }

  /** 参加者を追加する */
  private addParticipant() {
    if (!this.event.participants) {
      this.event.participants = [];
    }
    this.event.participants.push(DefaultCalendarParticipant());
  }

  /** 場所アイコンをクリックする */
  private clickLocate() {
    const a = document.createElement("a");
    a.target = "_blank";
    a.href = this.event.locate;
    a.click();
  }

  /** 日本語表記の曜日を返す */
  private strWeekdays(): string[] {
    const result: string[] = [];
    const weekdays = this.event.week_days.split("");
    weekdays.forEach((weekday, i) => {
      if (weekday === "1") {
        result.push(WeekDays[i]);
      }
    });
    return result;
  }

  /** 日本語表記の週目を返す */
  private strWeekNumbers(): string[] {
    const result: string[] = [];
    const weeks = this.event.weeks.split("");
    weeks.forEach((week, i) => {
      if (week === "1") {
        result.push(i + 1 + "週目");
      }
    });
    return result;
  }

  /** 日本語表記の週回を返す */
  private strWeekCounts(): string[] {
    const result: string[] = [];
    const weeks = this.event.week_times.split("");
    weeks.forEach((week, i) => {
      if (week === "1") {
        result.push(i + 1 + "回目");
      }
    });
    return result;
  }

  /** 0埋めをする */
  private zeroPadding(val: number): string {
    return String(val).padStart(2, "0");
  }

  /** 終了日の検査 */
  private validateEventDate(): boolean | string {
    if (this.event.repeat_div === RepeatDiv.None) {
      if (this.event.start_date > this.event.end_date) {
        return "終了日は開始日以降に設定してください";
      }
    }
    return true;
  }

  /** 終了日の検査 */
  private validateEventTime(): boolean | string {
    if (
      this.event.repeat_div === RepeatDiv.None &&
      this.event.start_date == this.event.end_date
    ) {
      if (
        this.event.start_hh > this.event.end_hh ||
        (this.event.start_hh === this.event.end_hh &&
          this.event.start_mm > this.event.end_mm)
      ) {
        return "終了時間は開始時間以降に設定してください";
      }
    }
    return true;
  }
}
