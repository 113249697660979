











import { Vue, Component, Emit, Prop } from "vue-property-decorator";
import AppToggleButton from "./AppToggleButton.vue";

// 曜日ボタン
@Component({
  components: {
    AppToggleButton
  }
})
export default class AppWeekdayButton extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;

  // 曜日選択肢
  @Prop({ default: () => ["日", "月", "火", "水", "木", "金", "土"] })
  weekDays!: Array<string>;
  // 値
  @Prop({ default: "0000000" }) value!: string;

  /*
   * emit
   */
  @Emit()
  private input(val: string) {
    return val;
  }

  /*
   * computed
   */

  private get Value(): string {
    return this.value;
  }
  private set Value(val: string) {
    this.input(val);
  }
}
