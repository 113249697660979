








































import { Component, Prop, Vue } from "vue-property-decorator";
import { Ewelluser } from "@/types/ewelluser";
import PractitionerTable from "@/components/userinfo/edit/mypageitem/practitioner/PractitionerTable.vue";

@Component({ components: { PractitionerTable } })
export default class extends Vue {
  @Prop() private readonly user!: Ewelluser;

  /** 特定行為研修の修了の有の時チェック */
  private checkTrainingCompletion(): boolean | string {
    // 特定行為研修の修了の有無で(1：有)を入力した場合は、修了した特定行為区分か修了したパッケージ研修のどちらかの入力が必須であり、それ以外の場合は入力不可
    if (!this.user.practitioner_training_completion) return true;
    const somePracDivCheck = this.user.ewell_user_practitioner_parent_divs.some(
      pracDiv => pracDiv.is_check === 1
    );
    const someAptCheck = this.user.ewell_user_area_package_trainings.some(
      apt => apt.is_check === 1
    );
    if (somePracDivCheck || someAptCheck) {
      return true;
    }
    return "特定行為研修の修了が有の場合は、修了した特定行為区分か領域別パッケージ研修のいずれかを選択してください。";
  }
}
