


















































import { Component, Vue, Prop } from "vue-property-decorator";
import { office } from "@/types/ibow2";

@Component
export default class extends Vue {
  /** 事業所 */
  @Prop({ default: () => ({}) }) private readonly office!: office;

  /** アカウント通知書を開く */
  private clickAccountInfo(office: office) {
    this.$router.push({
      path: "/ibow2/contract/account/",
      query: { range: office.agreement_range, date: office.agreement_date }
    });
  }
}
