


























import { Component, Mixins, Ref } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import AppPhoneNo from "#/components/AppPhoneNo.vue";
import ChangeForm from "@/components/userinfo/info/change/ChangeForm.vue";

@Component({ components: { AppPhoneNo, ChangeForm } })
export default class extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form") private readonly form!: { validate: () => boolean };

  private user: Ewelluser = DefaultEwelluser();

  /** 携帯電話番号 */ private phone = "";

  /** 携帯電話番号の登録がある */ private phoneDisplay = false;

  mounted() {
    /** データ取得 */
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
      this.phone = this.user.tel_no;
      if (this.phone) {
        this.phoneDisplay = true;
      }
    });
  }

  //携帯電話番号変更
  private async changePhone() {
    if (
      !(await this.$openConfirm("携帯電話番号を変更します。よろしいですか？"))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/confirm",
      {
        phone: this.phone
      },
      res => {
        this.$router.push({
          path: "/userinfo/phone/confirm?token=" + res.data.token
        });
      }
    );
  }

  //電話番号削除
  private async reset() {
    if (
      !(await this.$openConfirm("携帯電話番号を削除します。よろしいですか？"))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/auth/reset",
      {
        reset_type: 2
      },
      () => {
        this.goBack();
      }
    );
  }

  /** ページを戻る */
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/userinfo");
    }
  }
}
