








import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class AppSheet extends Vue {
  @Prop({ default: false }) noPadding!: boolean;
  @Prop({ default: false }) HiddenActionArea!: boolean;
}
