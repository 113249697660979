

































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import { ServiceLink } from "@/model/serviceLink/type";
import { StaffCorp, PortalAccount } from "@/types/mypage";
import Title from "@/components/common/Title.vue";
import UserInfo from "@/components/mypage/UserInfo.vue";
import OfficeList from "@/components/mypage/OfficeList.vue";
import PortalList from "@/components/mypage/PortalOfficeList.vue";
import Calendar from "@/components/mypage/Calendar.vue";
import Store from "@/components/mypage/Store.vue";

@Component({
  components: { Title, UserInfo, OfficeList, PortalList, Calendar, Store }
})
export default class extends Mixins(AxiosMixin) {
  /** ユーザー情報 */
  private user: Ewelluser = DefaultEwelluser();

  /** 事業所一覧 */
  private corps: StaffCorp[] = [];

  /** サービスリンク一覧 */
  private serviceLinks: ServiceLink[] = [];

  /** ポータル職員一覧 */
  private portal_staffs: PortalAccount[] = [];

  /** ポータル紹介者一覧 */
  private portal_request_users: PortalAccount[] = [];

  /** カレンダーエリアの列数 */
  private get CalendarCols() {
    if (this.NeedIbowLogin) {
      return this.BreakPoint ? 6 : 12;
    }
    return this.BreakPoint ? 9 : 12;
  }

  /** ブレイクポイント */
  private get BreakPoint() {
    return this.$vuetify.breakpoint.width > 767;
  }

  /** iBowログインエリアが必要か */
  private get NeedIbowLogin() {
    return this.corps.length > 0;
  }

  /** ポータルログインエリアが必要か */
  private get NeedPortalLogin() {
    return (
      this.portal_staffs.length > 0 || this.portal_request_users.length > 0
    );
  }

  mounted(): void {
    this.fetchAccountInfo();
    this.fetchOfficeList();
    this.fetchServiceLinks();
    this.fetchPortalList();
  }

  /** アカウントデータ取得 */
  private fetchAccountInfo() {
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
    });
  }

  /** 事業所データ取得 */
  private fetchOfficeList() {
    this.postJsonCheck(window.base_url + "/api/office/list", {}, res => {
      this.corps = res.data.corps;
    });
  }

  /** サービスリンク取得 */
  private fetchServiceLinks() {
    this.postJsonCheck(
      window.base_url + "/api/service_links/get",
      {
        is_posted: true,
        show_home: true,
        sort_type: 2
      },
      res => {
        this.serviceLinks = res.data.service_links;
      }
    );
  }

  /** ポータルアカウントデータ取得 */
  private fetchPortalList() {
    this.postJsonCheck(window.base_url + "/api/portal/list", {}, res => {
      this.portal_staffs = res.data.portal_staffs;
      this.portal_request_users = res.data.portal_request_users;
    });
  }
}
