



















import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { DefaultServiceLink } from "@/model/serviceLink/type";
import { LINK_TYPE } from "@/components/serviceLink/type";

@Component
export default class extends Mixins(AxiosMixin) {
  private serviceLink = DefaultServiceLink();

  mounted(): void {
    this.fetch();
  }

  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/service_link/get",
      {
        ids: [Number(this.$route.params.id)],
        link_type: LINK_TYPE.INTERMEDIATE,
        is_posted: true
      },
      async res => {
        if (res.data.message != "") {
          await this.$openAlert(res.data.message);
          this.goBack();
          return;
        }
        this.serviceLink = res.data.service_link;
      }
    );
  }

  //戻る処理
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    }
  }
}
