














import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppRadio extends Mixins(RulesSaveMixin) {
  /** 値 */
  @Prop() private readonly value!: unknown;

  @Emit() input(newValue: unknown) {
    return newValue;
  }

  private get Value(): unknown {
    return this.value;
  }

  private set Value(newValue: unknown) {
    this.input(newValue);
  }
}
