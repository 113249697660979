


























import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import Cookies from "js-cookie";
import { OfficeStaff } from "@/types/mypage";

@Component
export default class extends Mixins(AxiosMixin) {
  /** 事業所職員一覧 */
  @Prop({ default: () => ({}) }) private readonly item!: OfficeStaff;

  /** 企業名 */
  @Prop({ default: "" }) private readonly corp_name!: string;

  /** データ更新 */
  @Emit() private update() {
    return;
  }

  //iBowを使う
  private clickIbow(item: OfficeStaff) {
    //トークン保存
    this.postJsonCheck(
      window.base_url + "/api/auth/token",
      {
        office_staff_id: item.id
      },
      res => {
        window.token = res.data.token;
        Cookies.set("token", res.data.token, {
          domain: window.cookie_domain
        });
        window.reftoken = res.data.ref_token;
        Cookies.set("reftoken", res.data.ref_token, {
          domain: window.cookie_domain
        });
        location.href = window.ibow_frontend_url;
      }
    );
  }

  //招待承認
  private async inviteApprove(
    corp_name: string,
    item: OfficeStaff
  ): Promise<void> {
    if (
      !(await this.$openConfirm(
        corp_name + "からの招待を承認します。よろしいですか？"
      ))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/office/approve",
      {
        office_staff: item.office_staff
      },
      res => {
        if (res.data.token && res.data.ref_token) {
          window.token = res.data.token;
          Cookies.set("token", res.data.token, {
            domain: window.cookie_domain
          });
          window.reftoken = res.data.ref_token;
          Cookies.set("reftoken", res.data.ref_token, {
            domain: window.cookie_domain
          });
        }
        this.update();
      }
    );
  }
}
