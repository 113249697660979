































import { Component, Vue, Emit } from "vue-property-decorator";
import LoginArea from "./login/LoginArea.vue";
import DoubleLoginArea from "./login/DoubleLoginArea.vue";
import LoginTitle from "@/components/app/login/LoginTitle.vue";

@Component({
  components: { LoginArea, DoubleLoginArea, LoginTitle }
})
export default class Login extends Vue {
  /** パスワードリセット処理 */
  @Emit() private passwordReset() {
    return;
  }

  /** ログイン終了 */
  @Emit() private login(isOpenRegistDialog = false) {
    return isOpenRegistDialog;
  }

  /** 2段階認証フラグ */
  private isDoubleLogin = false;

  /** EweLLUserID */
  private ewellUserId = 0;

  /** ログアウト後のログイン画面かどうか */
  private get wasLogout(): boolean {
    return this.$route.query.action === "logout";
  }

  private get IframeSrc(): string {
    return (
      "https://infomationauth.ibowservice.jp/?time=" + new Date().getTime()
    );
  }

  /** ログイン終了 */
  private finishLogin(isOpenRegistDialog = false) {
    if (this.$route.query.return_url) {
      location.href = this.$route.query.return_url as string;
    } else {
      this.login(isOpenRegistDialog);
    }
  }

  /** 2段階認証 */
  private continuedLogin(ewellUserId: number) {
    this.ewellUserId = ewellUserId;
    this.isDoubleLogin = true;
  }
}
