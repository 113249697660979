






















import { Component, Vue, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";

@Component
export default class OfficeList extends Vue {
  @Prop({ default: () => ({}) }) private readonly header!: DataTableHeader;
}
