













import { Component, Vue, Prop } from "vue-property-decorator";
import { Ewelluser } from "@/types/ewelluser";
import FixItem from "./fixarea/FixItem.vue";

@Component({ components: { FixItem } })
export default class extends Vue {
  /** iBowアカウント情報 */
  @Prop() private readonly user!: Ewelluser;
}
