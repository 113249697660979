



















































































import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { PractitionerParentDiv } from "@/types/ewelluser";
import RulesMixin from "#/mixins/rulesMixin";
import AppCheckBox from "#/components/AppCheckbox.vue";

@Component({
  components: {
    AppCheckBox
  }
})
export default class PractitionerTable extends Mixins(RulesMixin) {
  @Prop() value!: PractitionerParentDiv[];
  @Prop({ default: false }) private_mode!: boolean;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) show_training_completion!: boolean; //指定研修番号の表示

  @Emit("input") input(practitionerParentDivs: PractitionerParentDiv[]) {
    return practitionerParentDivs;
  }

  private panel = 0;

  private get PractitionerParentDivs() {
    return this.value;
  }
  private set PractitionerParentDivs(
    practitionerParentDivs: PractitionerParentDiv[]
  ) {
    this.input(practitionerParentDivs);
  }

  private async changePractitionerParentDiv(
    practitionerParentDiv: PractitionerParentDiv
  ) {
    if (this.private_mode) {
      if (!practitionerParentDiv.is_privated) return;
    } else {
      if (!practitionerParentDiv.is_check) return;
    }
    if (
      !(await this.$openConfirm(
        "対応する特定行為を一緒にチェックします。\nよろしいですか。"
      ))
    )
      return;

    practitionerParentDiv.ewell_user_practitioners.forEach(prac => {
      if (this.private_mode) {
        prac.is_privated = 1;
      } else {
        prac.is_check = 1;
      }
    });
  }
}
