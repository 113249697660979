import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import MyPage from "@/views/mypage/MyPage.vue";
import UserInfo from "@/views/userinfo/UserInfo.vue";
import UserInfoEdit from "@/views/userinfo/edit/UserInfoEdit.vue";
import UserInfoPublishSetting from "@/views/userinfo/UserInfoPublishSetting.vue";
import PasswordChange from "@/views/userinfo/password/PasswordChange.vue";
import MailChange from "@/views/userinfo/mail/MailChange.vue";
import ConfirmMailArea from "@/views/userinfo/mail/ConfirmMailArea.vue";
import PhoneChange from "@/views/userinfo/phone/PhoneChange.vue";
import ConfirmPhoneArea from "@/views/userinfo/phone/ConfirmPhoneArea.vue";
import OfficeList from "@/views/ibow2/OfficeList.vue";
import ContractInfo from "@/views/ibow2/ContractInfo.vue";
import AccountInfo from "@/views/ibow2/AccountInfo.vue";
import ContractEdit from "@/views/ibow2/ContractEdit.vue";
import MyCalendar from "@/views/calendar/Calendar.vue";
import UserInfoConfirmDelete from "@/views/userinfo/delete/ConfirmUserDelete.vue";
import OfficeStaffLinkHistories from "@/views/officestaff/OfficeStaffLinkHistories.vue";
import ServiceLinks from "@/views/serviceLink/ServiceLinks.vue";
import InquiryForm from "@/views/serviceLink/InquiryForm.vue";
import IntermediatePage from "@/views/serviceLink/IntermediatePage.vue";
import PortalAccountList from "@/views/portal/AccountList.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "マイページ",
    component: MyPage
  },
  {
    path: "/userinfo",
    name: "アカウント情報",
    component: UserInfo
  },
  {
    path: "/userinfo/edit",
    name: "アカウント情報編集",
    component: UserInfoEdit
  },
  {
    path: "/user-info-publish-setting",
    name: "アカウント情報の公開設定",
    component: UserInfoPublishSetting
  },
  {
    path: "/userinfo/password/change",
    name: "アカウントパスワード変更",
    component: PasswordChange
  },
  {
    path: "/userinfo/mail/change",
    name: "メールアドレス登録・変更",
    component: MailChange
  },
  {
    path: "/userinfo/mail/confirm",
    name: "メールアドレス確認",
    component: ConfirmMailArea
  },
  {
    path: "/userinfo/phone/change",
    name: "電話番号登録・変更",
    component: PhoneChange
  },
  {
    path: "/userinfo/phone/confirm",
    name: "電話番号確認",
    component: ConfirmPhoneArea
  },
  {
    path: "/ibow2/office/list",
    name: "所属一覧",
    component: OfficeList
  },
  {
    path: "/ibow2/contract/info",
    name: "契約情報",
    component: ContractInfo
  },
  {
    path: "/ibow2/contract/account",
    name: "アカウント通知書",
    component: AccountInfo
  },
  {
    path: "/ibow2/contract/edit/:id",
    name: "変更届申請",
    component: ContractEdit
  },
  {
    path: "/calendar/mycalendar",
    name: "Myカレンダー",
    component: MyCalendar
  },
  {
    path: "/accountinfo/confirm/delete",
    name: "アカウント削除確認",
    component: UserInfoConfirmDelete
  },
  {
    path: "/office-staff/link-histories",
    name: "紐付け履歴",
    component: OfficeStaffLinkHistories
  },
  {
    path: "/service-link",
    name: "サービスリンク",
    component: ServiceLinks
  },
  {
    path: "/service-link/inquiry-form/:id",
    name: "サービスお問い合わせフォーム",
    component: InquiryForm
  },
  {
    path: "/service-link/intermediate-page/:id",
    name: "中間ページ",
    component: IntermediatePage
  },
  {
    path: "/carelogood/account/list",
    name: "けあログッと所属一覧",
    component: PortalAccountList
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
