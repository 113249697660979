































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import * as master from "@/store/modules/master/types";
import { namespace } from "vuex-class";
import LoginMixin from "#/mixins/loginMixin";
import Title from "@/components/userinfo/info/Title.vue";
import ButtonArea from "@/components/userinfo/info/ButtonArea.vue";
import UserInfoArea from "@/components/userinfo/info/UserInfoArea.vue";
import FileInfo from "@/components/userinfo/info/FileInfo.vue";
import BiometricInfo from "@/components/userinfo/info/BiometricInfo.vue";

const Master = namespace(master.name);

@Component({
  components: { Title, ButtonArea, UserInfoArea, FileInfo, BiometricInfo }
})
export default class extends Mixins(AxiosMixin, LoginMixin) {
  @Master.Mutation(master.SET_MASTER) setMaster!: (payload: unknown) => {};

  private user: Ewelluser = DefaultEwelluser();

  mounted(): void {
    this.fetch();
  }

  /** データ取得 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
    });
  }

  /** アカウント情報の保存 */
  private saveEwellUser(
    afterFunc: () => void = () => {
      return;
    }
  ) {
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/save",
      {
        ewell_user: this.user
      },
      () => {
        afterFunc();
        this.fetch();
      }
    );
  }
}
