
























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { MonthIntervals, RepeatDiv } from "./common";
import AppSelect from "./../AppSelect.vue";
import NumberInput from "./../NumberInput.vue";

// 繰り返し毎月（日付指定）
@Component({
  components: {
    AppSelect,
    NumberInput
  }
})
export default class RepeatMonthlyDateAssign extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  // 繰り返し区分
  @Prop() repeat_div!: number;
  // 毎月（X日）
  @Prop() month_day!: number;
  // 間隔
  @Prop() interval!: number;

  /*
   * emit
   */

  @Emit("update:month_day")
  private updateMonthDay(val: number) {
    return val;
  }

  @Emit("update:interval")
  private updateInterval(val: number) {
    return val;
  }

  /*
   * data
   */

  private readonly MonthIntervals = MonthIntervals;

  /*
   * computed
   */

  // 毎月（X日）
  private get MonthDay(): number {
    return this.month_day;
  }
  private set MonthDay(val: number) {
    this.updateMonthDay(val);
  }

  // 間隔
  private get Interval(): number {
    return this.interval;
  }
  private set Interval(val: number) {
    this.updateInterval(val);
  }

  /*
   * method
   */

  // バリデーション 日付
  private validateMonthDay(): boolean | string {
    if (this.repeat_div != RepeatDiv.EveryMonthSpecifiedDate) {
      return true;
    }
    if (!this.MonthDay) {
      return "必須です";
    }
    if (this.MonthDay < 1 || this.MonthDay > 31) {
      return "1日〜31日の間で入力してください";
    }
    return true;
  }
}
