





















import Vue from "vue";
import AppIcon from "./AppIcon.vue";
import { Component, Prop } from "vue-property-decorator";

export interface BreadItem {
  text: string;
  disabled: boolean;
  href: string;
}

@Component({
  components: {
    AppIcon
  }
})
export default class AppBreadcrumbs extends Vue {
  @Prop({ default: () => [] }) items!: BreadItem[];
}
