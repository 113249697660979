















































































































































































import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import { Menu, SubMenu, SideMenu } from "@/types";
import {
  accountMenu,
  ibowMenu,
  calendarMenu,
  linkMenu,
  ibowMenuNotAgree,
  portalMenu
} from "@/menus";
import AxiosMixin from "@/mixins/axiosMixin";
import { MenuInfo } from "@/types/main";

@Component
export default class AppMenu extends Mixins(AxiosMixin) {
  @Prop() sideMenu!: SideMenu;

  /** 契約情報メニュー閲覧フラグ */
  @Prop() menuInfo!: MenuInfo;

  /** 新規契約申込受付フラグ */
  @Prop({ default: false }) newAgree!: boolean;

  @Emit()
  private toggleSideOpen() {
    return;
  }
  //サイドメニューを狭くする(PC幅)
  @Emit()
  private toggleSideNarrow() {
    return;
  }

  //サイドメニューの縮小を解除
  private sideExpand() {
    if (this.sideMenu.isNarrow) {
      this.toggleSideNarrow();
    }
  }
  /** メニュー */
  private get Menus(): Menu[] {
    let menus: Menu[] = [];
    menus = menus.concat(accountMenu);
    if (this.menuInfo.is_use_ibow) {
      if (this.menuInfo.is_menu_agree_info) {
        menus = menus.concat(ibowMenu);
      } else {
        menus = menus.concat(ibowMenuNotAgree);
      }
    }
    menus = menus.concat(calendarMenu);
    menus = menus.concat(linkMenu);
    if (this.menuInfo.is_use_portal) {
      menus = menus.concat(portalMenu);
    }
    return menus;
  }

  created(): void {
    if (!this.$vuetify.breakpoint.lgAndUp) {
      this.sideMenu.isOpen = false;
    }
  }

  private linkto(menu: SubMenu) {
    // 同じページには遷移しない
    if (this.isSameUrl(menu.url)) return;
    if (menu.is_outer) {
      location.href = window.middleend_url + menu.url;
    } else {
      this.$router.push(menu.url);
    }
  }

  //新規契約の申し込み画面
  private clickNewAgree() {
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/check-mail",
      {},
      async res => {
        if (res.data.mail) {
          location.href = window.middleend_url + "/appform";
        } else {
          if (
            await this.$openConfirm(
              "iBowのお申し込みには、メールアドレスが必要です。メールアドレスを登録しますか？"
            )
          ) {
            this.$router.push("/userinfo/mail/change");
          }
        }
      }
    );
  }

  //ホームへ
  private toHome() {
    // 同じページには遷移しない
    if (this.isSameUrl("/")) return;
    this.$router.push("/");
  }

  // URLのパス部のスラッシュ有無を統一
  private get url() {
    return (url: string) => {
      // 先頭スラッシュは付ける
      url = url[0] === "/" ? url : "/" + url;
      // ホーム画面でない限り末尾スラッシュは付けない
      url = url.slice(-1) === "/" && url !== "/" ? url.slice(0, -1) : url;
      return url;
    };
  }
  // 遷移しようとするURLは現在のと同じか
  private get isSameUrl() {
    return (urlTo: string) => {
      const urlSlashCount = this.url(urlTo).match(/\//g)?.length ?? 0;
      // ルートの子階層URLが遷移先であれば、子孫にもマッチする
      if (urlSlashCount === 1 && urlTo.length !== 1) {
        return this.$route.path.startsWith(this.url(urlTo));
      }
      return this.url(urlTo) === this.$route.path;
    };
  }

  private toLink(url: string) {
    this.$router.push({
      path: url
    });
  }
}
