




































import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";

interface HistoryInfo {
  history_date: string;
  staff_id: number;
  status_message: string;
  office_name: string;
}

@Component
export default class extends Mixins(AxiosMixin) {
  /** 職員履歴 */
  private historyInfos: HistoryInfo[] = [];

  mounted(): void {
    this.fetch();
  }

  /** 職員履歴取得 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/link-histories/get", {}, res => {
      this.historyInfos = res.data.history_infos;
    });
  }
}
