



































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { agreement } from "@/types/ibow2";

@Component
export default class extends Mixins(AxiosMixin) {
  @Prop({ default: () => ({}) }) private readonly company!: agreement;

  @Emit() private update() {
    return;
  }

  /** 変更申請取消し */
  private async clickAgreementConfirmDelete(company: agreement) {
    if (!(await this.$openConfirm("申請を取り消します。よろしいですか？"))) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/agreement/delete",
      {
        agreement_info: company.proto,
        co_code: company.co_code
      },
      () => {
        this.update();
      }
    );
  }

  /** 契約事業所追加 */
  private clickNewOffice(company: agreement) {
    location.href = window.middleend_url + "/appform/new_office/" + company.id;
  }
}
