







import { Component, Vue } from "vue-property-decorator";
import Title from "@/components/common/Title.vue";
import MyCalendar from "@/components/calendar/MyCalendar.vue";

@Component({ components: { Title, MyCalendar } })
export default class extends Vue {}
