

















import { Component, Prop, Vue } from "vue-property-decorator";
import { ServiceLink } from "@/model/serviceLink/type";
import { LINK_TYPE } from "@/components/serviceLink/type";
import AppIcon from "#/components/AppIcon.vue";

@Component({
  components: {
    AppIcon
  }
})
export default class LinkCard extends Vue {
  /** サービスリンク */
  @Prop() private serviceLink!: ServiceLink;
  /** 画像のみ表示 */
  @Prop({ default: false }) private imageOnly!: boolean;

  private show = false;

  private toLink(link: ServiceLink) {
    switch (link.type) {
      case LINK_TYPE.DIRECT:
        window.open(link.url, "_blank");
        break;
      case LINK_TYPE.MAIL:
        this.$router.push({
          path: `/service-link/inquiry-form/${String(link.id)}`
        });
        break;
      case LINK_TYPE.INTERMEDIATE:
        this.$router.push({
          path: `/service-link/intermediate-page/${String(link.id)}`
        });
        break;

      default:
        break;
    }
  }

  private imageClass() {
    return this.imageOnly
      ? "c-link-card__img c-link-card__img--image-only"
      : "c-link-card__img";
  }
}
