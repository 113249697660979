import Base from "../base";

// 参加者状態インターフェース
export interface CalendarParticipantStatus extends Base {
  id: number; // 参加者状態ID
  participant_id: number; // 参加者ID
  date: string; // 年月日
  status: number; // 状態 1:参加しない 2:参加する
}
export const DefaultCalendarParticipantStatus = (): CalendarParticipantStatus => ({
  id: 0,
  participant_id: 0,
  date: "",
  status: 0,
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

// 参加者インターフェース
export interface CalendarParticipant extends Base {
  id: number; // 参加者ID
  event_id: number; // イベントID
  staff_id: number; // 利用者ID
  name: string; // 参加者名
  statuses: CalendarParticipantStatus[]; // 参加状態
}
export const DefaultCalendarParticipant = (): CalendarParticipant => ({
  id: 0,
  event_id: 0,
  staff_id: 0,
  name: "",
  statuses: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

// カレンダーイベントインターフェース
export interface CalendarEvent extends Base {
  id: number; // イベントID
  layer_id: number; // レイヤーID
  color: string; // カラーコード
  title: string; // タイトル
  content: string; // コンテンツ
  locate: string; // 場所
  status: number; // 状態 0:キャンセル 1:有効
  start_date: string; // 予定開始年月日
  start_hh: number; // 開始時刻（時）
  start_mm: number; // 開始時刻（分）
  end_date: string; // 予定終了年月日
  end_hh: number; // 終了時刻（時）
  end_mm: number; // 終了時刻（分）
  all_day: number; // 終日フラグ
  repeat_div: number; // 繰り返し設定 1:繰り返しなし 2:毎日 3:毎週 4:毎月（日付指定） 5:毎月（週指定） 6:毎月（曜日指定）
  weeks: string; // 週（X週目）
  week_days: string; // 曜日（X曜日）
  week_times: string; // 週（X回目）
  month_day: number; // 毎月（X日）
  interval: number; // 間隔
  limit_div: number; // 期限区分 0:日付 :1回数
  limit_count: number; // 期限回数
  limit_start_date: string; // 期限開始日
  limit_end_date: string; // 期限終了日
  participants: CalendarParticipant[]; // 参加者リスト
}
export const DefaultCalendarEvent = (): CalendarEvent => ({
  id: 0,
  layer_id: 0,
  color: "",
  title: "",
  content: "",
  locate: "",
  status: 1,
  start_date: "",
  start_hh: 0,
  start_mm: 0,
  end_date: "",
  end_hh: 0,
  end_mm: 0,
  all_day: 0,
  repeat_div: 1,
  weeks: "000000",
  week_days: "0000000",
  week_times: "00000",
  month_day: 0,
  interval: 0,
  limit_div: 0,
  limit_count: 0,
  limit_start_date: "",
  limit_end_date: "",
  created_at: "",
  updated_at: "",
  deleted_at: "",
  participants: []
});
