























































































































































































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import OfficeSelect from "@/components/serviceLink/OfficeSelect.vue";
import InfomationDialog from "@/components/serviceLink/InfomationDialog.vue";
import { VForm } from "#/components/FileUpload.vue";
import * as master from "@/store/modules/master/types";
import { namespace } from "vuex-class";
import RulesMixin from "#/mixins/rulesMixin";
import { InquiryFormOffice } from "@/types/mypage";
import {
  DefaultServiceLinkMailInfo,
  DefaultServiceLink
} from "@/model/serviceLink/type";

const Master = namespace(master.name);

@Component({ components: { OfficeSelect, InfomationDialog } })
export default class extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form") private readonly form!: VForm;

  @Master.Mutation(master.SET_MASTER) setMaster!: (payload: unknown) => {};

  private user: Ewelluser = DefaultEwelluser();
  private serviceLink = DefaultServiceLink();
  private serviceLinkMailInfo = DefaultServiceLinkMailInfo();
  private mailAddresses: string[] = [];
  private privacyProtection = false;
  private sendComplete = false;
  private coCode = "";
  private kstNo = "";

  private office: InquiryFormOffice = {
    id: 0,
    corp_name: "",
    office_name: "",
    address: "",
    post_code: "",
    agreement_id: 0,
    kst_no: "",
    co_code: ""
  };

  mounted(): void {
    this.fetch();
  }

  //データ取得
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/service_link/inquiry_form/get",
      {
        id: Number(this.$route.params.id)
      },
      async res => {
        if (res.data.message != "") {
          await this.$openAlert(res.data.message);
          this.goBack();
          return;
        }

        this.user = res.data.ewell_user;
        this.serviceLink = res.data.service_link;
        this.serviceLinkMailInfo.service_link_id = this.serviceLink.id;
        this.serviceLinkMailInfo.family_name = this.user.family_name;
        this.serviceLinkMailInfo.first_name = this.user.first_name;
        this.serviceLinkMailInfo.family_name_furigana = this.user.family_name_furigana;
        this.serviceLinkMailInfo.first_name_furigana = this.user.first_name_furigana;
        this.serviceLinkMailInfo.mail_address = this.user.mail_address;
        this.serviceLinkMailInfo.tel = this.user.tel_no;
        this.serviceLinkMailInfo.remarks = this.serviceLink.remarks;
        this.serviceLinkMailInfo.system_id = this.user.system_id;
        this.mailAddresses = this.serviceLink.service_link_mails.map(mail => {
          return mail.mail_address;
        });
      }
    );
  }

  //データ保存
  private async clickSubmit() {
    if (!this.form.validate()) {
      await this.$openAlert("入力内容に不備があります");
      return;
    }

    // メール送信 + ログ保存
    this.postJsonCheck(
      window.base_url + "/api/service_link/mail/send",
      {
        title: this.serviceLink.title,
        category_name: this.serviceLink.service_link_category.name,
        service_link_mail_info: this.serviceLinkMailInfo,
        mail_addresses: this.mailAddresses,
        footer_comment: this.serviceLink.footer_comment,
        co_code: this.coCode,
        kst_no: this.kstNo
      },
      () => {
        this.sendComplete = true;
      }
    );
  }

  // 事業所変更
  private changeOffice() {
    if (this.office.id != 0) {
      // 会社情報が手入力ではない場合
      this.serviceLinkMailInfo.station_name = this.office.office_name;
      this.serviceLinkMailInfo.address = this.office.address;
      this.serviceLinkMailInfo.corp_name = this.office.corp_name;
      this.serviceLinkMailInfo.post_code = this.office.post_code;
      this.serviceLinkMailInfo.kst_no = this.office.kst_no;
      this.serviceLinkMailInfo.co_code = this.office.co_code;
    } else {
      // 手入力の場合
      this.serviceLinkMailInfo.station_name = "";
      this.serviceLinkMailInfo.address = "";
      this.serviceLinkMailInfo.corp_name = "";
      this.serviceLinkMailInfo.post_code = "";
      this.serviceLinkMailInfo.kst_no = "";
      this.serviceLinkMailInfo.co_code = "";
    }
  }

  private closeDialog() {
    this.sendComplete = false;
    this.goBack();
  }

  //戻る処理
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    }
  }

  //iBowストア一覧遷移
  private goList() {
    this.$router.replace("/service-link");
  }
}
