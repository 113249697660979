



















import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import { agreement } from "@/types/ibow2";
import ButtonArea from "@/components/ibow2/contractinfo/corp/ButtonArea.vue";
import CorpInfo from "@/components/ibow2/contractinfo/corp/CorpInfo.vue";
import OfficeInfo from "@/components/ibow2/contractinfo/corp/OfficeInfo.vue";

@Component({ components: { ButtonArea, CorpInfo, OfficeInfo } })
export default class extends Vue {
  /** 法人 */
  @Prop({ default: () => ({}) }) private readonly company!: agreement;

  @Emit() private update() {
    return;
  }
}
