















import { Component, Mixins, Emit, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { InquiryFormStaffCorp, InquiryFormOffice } from "@/types/mypage";

@Component
export default class OfficeSelect extends Mixins(AxiosMixin) {
  @Prop({ default: {} }) value!: InquiryFormOffice;
  @Emit() input(val: InquiryFormOffice) {
    return val;
  }
  @Emit("onChange") onChange(val: InquiryFormOffice) {
    return val;
  }

  private offices: InquiryFormOffice[] = [];
  private selectOffice: unknown = {};

  get Office() {
    return this.value;
  }
  set Office(val: InquiryFormOffice) {
    this.input(val);
  }

  mounted(): void {
    this.fetch();
  }

  //データ更新
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/office/get/corps",
      {
        use_select: true
      },
      res => {
        res.data.corps.forEach((corp: InquiryFormStaffCorp) => {
          this.offices.push(...corp.offices);
        });

        if (this.offices.length > 1) {
          this.Office = this.offices[1];
        } else {
          this.Office = this.offices[0];
        }
        this.onChange(this.Office);
      }
    );
  }
}
