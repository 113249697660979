













import { Component, Mixins } from "vue-property-decorator";
import { Corp } from "@/types/ibow2";
import AxiosMixin from "@/mixins/axiosMixin";
import Title from "@/components/common/Title.vue";
import CorpList from "@/components/ibow2/officelist/CorpList.vue";

@Component({ components: { Title, CorpList } })
export default class extends Mixins(AxiosMixin) {
  /** 法人一覧 */ private corps: Corp[] = [];

  mounted(): void {
    this.fetch();
  }

  /** データ更新 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/office/list", {}, res => {
      this.corps = res.data.corps;
    });
  }
}
