/** カテゴリ毎のサービスリンク情報 */
export interface ServiceLinksByCategories {
  /** カテゴリ */
  service_link_category: ServiceLinkCategory;
  /** サービスリンク情報 */
  service_links: ServiceLink[];
}

/** サービスリンク情報 */
export interface ServiceLink {
  /** ID */
  id: number;
  /** タイトル */
  title: string;
  /** 掲載期間開始日 */
  start_date: string;
  /** 掲載期間終了日 */
  end_date: string;
  /** URL */
  url: string;
  /** 画像 */
  img: string;
  /** GCP画像ファイルパス */
  img_path: string;
  /** カテゴリID */
  category_id: number;
  /** 中間ページ内容 */
  intermediate_page: string;
  /** 説明 */
  description?: string;
  /** メールアドレス(複数) */
  service_link_mails: ServiceLinkMail[];
  /** カテゴリ */
  service_link_category: ServiceLinkCategory;
  /** 1:直リンク 2:お問合せフォーム 3:中間ページ */
  type: number;
  /** マイページホーム画面で表示する */
  show_home: number;
  /** マイページサービスリンク一覧画面で表示する */
  show_service_links: number;
  /** ソート番号 */
  sort_no: number;
  /** ソート番号 */
  home_sort_no: number;
  /** 自動返信メール用フッターコメント */
  footer_comment?: string;
  /** お問合せ内容 */
  remarks: string;

  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export const DefaultServiceLink = (): ServiceLink => {
  return {
    id: 0,
    title: "",
    start_date: "",
    end_date: "",
    img: "",
    img_path: "",
    category_id: 0,
    type: 1,
    url: "",
    intermediate_page: "",
    description: "",
    sort_no: 0,
    home_sort_no: 0,
    service_link_category: {
      id: 0,
      name: "",
      created_at: "",
      deleted_at: "",
      updated_at: ""
    },
    service_link_mails: [],
    show_home: 1,
    show_service_links: 1,
    footer_comment: "",
    remarks: "",
    created_at: "",
    deleted_at: "",
    updated_at: ""
  };
};

/** サービスリンクメール */
export interface ServiceLinkMail {
  id: number;
  /** サービスリンクID */
  service_link_id: number;
  /** メールアドレス */
  mail_address: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

/** サービスリンクカテゴリ */
export interface ServiceLinkCategory {
  id: number;
  /** カテゴリ名 */
  name: string;
  created_at: string;
  updated_at: string;
  deleted_at: string;
}

/** サービスリンクメール情報（ログ用） */
export interface ServiceLinkMailInfo {
  service_link_id: number;
  /** iBowシステムID */
  system_id: string;
  /** 企業コード */
  co_code: string;
  /** 事業所コード */
  kst_no: string;
  /** iBowID */
  user_id: string;
  /** 送信者 姓 */
  family_name: string;
  /** 送信者 名 */
  first_name: string;
  /** 送信者 姓ふりがな */
  family_name_furigana: string;
  /** 送信者 名ふりがなk */
  first_name_furigana: string;
  /** 連絡先メールアドレス */
  mail_address: string;
  /** 連絡先電話番号 */
  tel: string;
  /** 法人名 */
  corp_name: string;
  /** ステーション名 */
  station_name: string;
  /** 郵便番号 */
  post_code: string;
  /** 住所 */
  address: string;
  /** お問合せ内容 */
  remarks: string;
}

export const DefaultServiceLinkMailInfo = (): ServiceLinkMailInfo => {
  return {
    service_link_id: 0,
    user_id: "",
    system_id: "",
    co_code: "",
    kst_no: "",
    corp_name: "",
    family_name: "",
    family_name_furigana: "",
    first_name: "",
    first_name_furigana: "",
    post_code: "",
    station_name: "",
    mail_address: "",
    tel: "",
    remarks: "",
    address: ""
  };
};
