























































import { CalendarDispEvent } from "#/components/calendar/common";
import { Component, Ref, Prop, Emit, Vue } from "vue-property-decorator";
import * as appDate from "#/utility/appDate";
import { Choice } from "#/types";
import { OfficeStaffChoice } from "#/model/staff";
import AppCalendar from "#/components/AppCalendar.vue";
import { EventType } from "#/model/schedule/calendarType";

@Component
export default class AppCalendarV2 extends Vue {
  /** カレンダーコンポーネント */
  @Ref("calendar") private readonly calendar!: AppCalendar;

  /** 表示基準日 */
  @Prop({ default: "" }) value!: string;

  /** 誕生日 */
  @Prop() birthday!: string;

  /** カレンダーイベント */
  @Prop({ default: () => [] }) events!: CalendarDispEvent[];

  /** カレンダータイプ 日・週・月 */
  @Prop({ default: "month" }) type!: string;

  /** カレンダーの高さ */
  @Prop({ default: "auto" }) height!: string;

  /** カレンダークラス */
  @Prop({ default: "" }) calendarClass!: string;

  /** 時間毎の間隔 */
  @Prop({ default: 80 }) intervalHeight!: number;

  /** 表示時間数 */
  @Prop({ default: 24 }) intervalCount!: number;

  /** 一番上の表示時間 */
  @Prop({ default: 0 }) firstInterval!: number;

  /** 利用者一覧 */
  @Prop({ default: () => [] }) patients!: Choice[];

  /** 職員一覧 */
  @Prop({ default: () => [] }) staffs!: OfficeStaffChoice[];

  /** ログイン職員ID */
  @Prop({ default: 0 }) loginStaffLayerId!: number;

  /** カテゴリー */
  @Prop({ default: () => [] }) categories!: string[];

  /** カテゴリを全て表示するかどうか */
  @Prop({ default: false }) categoryShowAll!: boolean;

  /** カレンダーイベントのマージン */
  @Prop({ default: 1 }) eventMarginBottom!: number;

  /** 表示基準日の変更 */
  @Emit() input(newValue: string) {
    return newValue;
  }

  /** カレンダータイプの変更 */
  @Emit("update:type") updateType(newValue: string) {
    return newValue;
  }

  /** カレンダーをクリックする */
  @Emit() clickCalendar(e: CalendarDispEvent) {
    return e;
  }

  /** カレンダーイベントを移動した時の処理 */
  @Emit()
  private moveEvent(e: {
    event: CalendarDispEvent;
    orgEvent: CalendarDispEvent;
  }) {
    return e;
  }

  /** カレンダーイベントをクリックした時の処理 */
  @Emit()
  private handleEvent(event: CalendarDispEvent) {
    return event;
  }

  /** データ更新 */
  @Emit() update() {
    return;
  }

  private eventType = EventType;

  /** 表示基準日 */
  private get Value(): string {
    return this.value;
  }
  private set Value(newValue: string) {
    this.input(newValue);
  }

  /** カレンダータイプ 日・週・月 */
  private get Type(): string {
    return this.type;
  }
  private set Type(newType: string) {
    this.updateType(newType);
  }

  /** カテゴリ（AppCalendar） */
  private get CalendarCategory() {
    if (this.categories.length === 0) {
      return this.Categories;
    } else {
      return this.categories;
    }
  }

  /** カテゴリ（職員単位） */
  private get Categories(): string[] {
    const categories: string[] = [];
    this.events.forEach(event => {
      if (
        (appDate.strToDate(event.start) <= // 日を跨ぐ予定（中日）
          appDate.strToDate(this.Value + " 00:00") &&
          appDate.strToDate(this.Value + " 23:59") <=
            appDate.strToDate(event.end)) ||
        (appDate.strToDate(this.Value + " 00:00") <= // 開始日が当日
          appDate.strToDate(event.start) &&
          appDate.strToDate(event.start) <=
            appDate.strToDate(this.Value + " 23:59")) ||
        (appDate.strToDate(this.Value + " 00:00") <= // 終了日が当日
          appDate.strToDate(event.end) &&
          appDate.strToDate(event.end) <=
            appDate.strToDate(this.Value + " 23:59"))
      ) {
        if (
          event.category &&
          event.category !== undefined &&
          categories.findIndex(cat => String(event.category) === cat) < 0 // 既に追加されていたらスキップ
        ) {
          categories.push(String(event.category));
        }
      }
    });

    // fetch時に入れ替わらないように職員IDで固定
    return categories.sort((a, b) => {
      if (Number(a) > Number(b)) {
        return 1;
      } else if (Number(a) < Number(b)) {
        return -1;
      }
      return 0;
    });
  }

  /** カレンダーのタイトル */
  public get title() {
    return this.calendar.title;
  }

  /** 前へ */
  public prev() {
    return this.calendar.prev();
  }

  /** 次へ */
  public next() {
    return this.calendar.next();
  }

  /** スクロール位置を戻す */
  public scrollToTime(time: string) {
    this.calendar.scrollToTime(time);
  }

  /** 職員名を取得する */
  private getStaffName(category: string): string {
    const staffId = Number(category);
    if (!isNaN(staffId)) {
      const staff = this.staffs.find(staff => staff.value == staffId);
      if (staff?.nick_name) {
        return staff.nick_name;
      }
    }
    return category;
  }
}
