






import { Component, Prop, Vue, Emit } from "vue-property-decorator";
import AppButton from "../AppButton.vue";
import { dateToStr } from "./../../utility/appDate";

@Component({
  components: {
    AppButton
  }
})
export default class NowButton extends Vue {
  /** カレンダータイプ */
  @Prop({ default: "" }) type!: string;

  /** 指定年月日 */
  @Prop({ default: "" }) value!: string;

  /** 指定年月日の変更時 */
  @Emit("change") private emitChange() {
    return;
  }

  /** 指定年月日の変更時 */
  @Emit("input") private emitInput(value: string) {
    return value;
  }

  /** ボタンラベル */
  private get Label() {
    const typeToNowLabel: { [key: string]: string } = {
      day: "今日",
      week: "今週",
      month: "今月",
      category: "今日"
    };
    return typeToNowLabel[this.type];
  }

  /** 対象年月日に本日を設定する */
  private setToday() {
    this.emitInput(dateToStr(new Date(), "yyyy-MM-dd"));
    this.emitChange();
  }
}
