



















import { Component, Prop, Emit, Mixins } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";

@Component
export default class InfomationDialog extends Mixins(UtilMixin) {
  @Prop({ default: false }) value!: boolean;

  @Prop({ default: "" }) title!: string; //タイトル
  @Prop({ default: "" }) message!: string; //本文
  @Prop({ default: "閉じる" }) closeLabel!: string; //閉じるラベル
  @Prop({ default: false }) isDelete!: boolean; //削除ボタン表示フラグ
  @Prop({ default: false }) needSave!: boolean; //保存ボタン表示フラグ
  @Prop({ default: true }) needClose!: boolean; //閉じるボタン表示フラグ

  @Emit() input(val: boolean) {
    return val;
  }
  @Emit("close")
  private closeDialog() {
    return;
  }

  get Value() {
    return this.value;
  }
  set Value(val: boolean) {
    this.input(val);
  }

  // キャンセルボタンクリック
  private cancel() {
    this.closeDialog();
  }
}
