import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#1976d2",
        append: "#10b77a",
        alert: "#E40113",
        cancel: "#595757",
        footer: "#F2F2F2",
        white: "#fff"
      }
    }
  }
});
