






































































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form") private readonly form!: { validate: () => boolean };

  private now_password_show = false; //現在のパスワード表示フラグ
  private new_password_show = false; //新しいパスワード表示フラグ
  private confirm_password_show = false; //確認用パスワード表示フラグ

  old_password = ""; //現在のパスワード
  password = ""; //新しいパスワード
  confirm_password = ""; //確認用パスワード
  passwordMatch = /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#$%])/; //パスワードの正規表現

  private user: Ewelluser = DefaultEwelluser();

  mounted(): void {
    this.updateData();
  }

  //データ更新
  private updateData() {
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
    });
  }

  private async changePassword() {
    if (!this.form.validate()) {
      return;
    }
    if (!this.password.match(this.passwordMatch) || this.password.length < 7) {
      await this.$openAlert(
        "パスワードには半角英数の大文字、小文字、記号(#$%)各1つ以上を含め、8文字以上で入力してください。"
      );
      return;
    }
    if (
      !(await this.$openConfirm("パスワードを変更します。よろしいですか？"))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/save",
      {
        ewell_user: this.user,
        password: this.password,
        old_password: this.old_password,
        confirm_password: this.confirm_password
      },
      () => {
        this.goBack();
      }
    );
  }

  //戻る処理
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/userinfo");
    }
  }
}
