


























import { Component, Emit, Vue, Prop } from "vue-property-decorator";
import { DataTableHeader } from "vuetify/types/index";
import { Corp } from "@/types/ibow2";
import HeaderCondition from "@/components/ibow2/officelist/corplist/HeaderCondition.vue";
import ItemCondition from "@/components/ibow2/officelist/corplist/ItemCondition.vue";

@Component({ components: { HeaderCondition, ItemCondition } })
export default class OfficeList extends Vue {
  @Prop({ default: () => ({}) }) private readonly corp!: Corp;

  @Emit() private update() {
    return;
  }

  private officeHeaders: DataTableHeader[] = [
    {
      text: "iBow設定",
      value: "condition",
      align: "start",
      width: "80px",
      cellClass: "text-start"
    },
    {
      text: "職員名",
      value: "nick_name",
      align: "start",
      width: "",
      cellClass: "text-start"
    },
    {
      text: "事業所名",
      value: "office_name",
      align: "start",
      width: "",
      cellClass: "text-start"
    },
    {
      text: "権限",
      value: "auth_name",
      align: "start",
      width: "",
      cellClass: "text-start"
    },
    {
      text: "職種",
      value: "staff_div_name",
      align: "start",
      width: "",
      cellClass: "text-start"
    },
    {
      text: "勤務状況",
      value: "status_name",
      align: "start",
      width: "",
      cellClass: "text-start"
    },
    {
      text: "標準利用のアカウント",
      value: "is_default",
      align: "start",
      width: "",
      cellClass: "text-start"
    },
    {
      text: "現在有効",
      value: "is_using",
      align: "start",
      width: "",
      cellClass: "text-start"
    }
  ];
}
