



















import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";

export interface Any {
  [key: string]: unknown;
}

@Component({
  components: {}
})
export default class SelectObject extends Vue {
  @Prop() value!: Any[];
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: "" }) title!: string;
  @Prop({ default: "" }) label!: string;
  @Prop({ default: "id" }) item_id!: string;
  @Prop({ default: "name" }) item_name!: string;
  @Prop({ default: "" }) item_code!: string;
  @Prop({ default: "is_check" }) check_key!: string;

  private isFirst = true;

  @Emit("input") input(val: Any[]) {
    return val;
  }

  @Emit("change") change(val: Any[]) {
    const isAdd = val.length > this.prevValue.length ? true : false;
    this.prevValue = val;
    return {
      items: val,
      is_add: isAdd
    };
  }

  @Watch("value", { immediate: true }) watchValue() {
    if (this.isFirst) {
      this.prevValue = this.value.filter(v => v[this.check_key] === 1);
    }
    this.isFirst = false;
  }

  private labelText = "";
  private prevValue: Any[] = [];

  mounted() {
    this.labelText = this.label === "" ? this.title : this.label;
  }

  private get Value() {
    return this.value;
  }
  private set Value(val: Any[]) {
    this.input(val);
  }

  private get SelectValue() {
    return this.value.filter(v => v[this.check_key] === 1);
  }

  private set SelectValue(val: Any[]) {
    this.value.forEach(v => {
      const isFind = val.find(sc => sc[this.item_id] === v[this.item_id]);
      v[this.check_key] = isFind ? 1 : 0;
    });
  }
}
