
















import { Vue, Component, Prop, Emit } from "vue-property-decorator";

@Component
export default class RangeChangeButtons extends Vue {
  @Prop() private readonly value!: string;

  @Prop({ default: true }) private showDate!: boolean;

  @Prop({ default: true }) private mandatory!: boolean;

  @Emit()
  input(newValue: string) {
    return newValue;
  }

  @Emit()
  change(newValue: string) {
    return newValue;
  }

  private set Value(newValue: string) {
    this.change(newValue);
    this.input(newValue);
  }

  private get Value(): string {
    return this.value;
  }
}
