
















































import { Component, Prop, Mixins } from "vue-property-decorator";
import {
  Ewelluser,
  Cert,
  PractitionerParentDiv,
  AreaPackageTraining
} from "@/types/ewelluser";
import RulesMixin from "#/mixins/rulesMixin";
import SelectCertifications from "@/components/userinfo/edit/mypageitem/SelectCertifications.vue";
import SelectObject from "#/components/SelectObject.vue";
import Icon from "@/components/userinfo/edit/mypageitem/Icon.vue";
import FixArea from "@/components/userinfo/edit/mypageitem/FixArea.vue";
import Emergency from "@/components/userinfo/edit/mypageitem/Emergency.vue";
import Basic from "@/components/userinfo/edit/mypageitem/Basic.vue";
import Practitioner from "./mypageitem/Practitioner.vue";
import { CERTIFICATION } from "#/const/certification";

@Component({
  components: {
    Icon,
    FixArea,
    Basic,
    Emergency,
    SelectCertifications,
    Practitioner,
    SelectObject
  }
})
export default class MyPageItem extends Mixins(RulesMixin) {
  @Prop() private readonly user!: Ewelluser;

  /** 特定行為が選択できるか判定 */
  private get IsUsePractitioner() {
    return this.user.ewell_user_certifications.some((cert: Cert) => {
      if (
        cert.certification_id != CERTIFICATION.CARE_MANAGER &&
        cert.certification_id != CERTIFICATION.OTHER &&
        cert.is_check
      ) {
        return true;
      }
    });
  }

  /** 専門看護師(特定行為)が選択されているか判定 */
  private get IsSelectNursePractitioner() {
    return this.user.ewell_user_certifications.some(
      (cert: Cert) =>
        cert.certification_id == CERTIFICATION.NURSSE_PRACTITIONER &&
        cert.is_check
    );
  }

  /** 特定行為に関するバリデーションチェック */
  private checkPractitioner(): boolean | string {
    let count = 0;
    let specifiedInstitueNumCount = 0;
    this.user.ewell_user_practitioner_parent_divs.forEach(
      (practitionerDiv: PractitionerParentDiv) => {
        if (practitionerDiv.specified_institute_num) {
          specifiedInstitueNumCount++;
        }
        practitionerDiv.ewell_user_practitioners.forEach(practitioner => {
          if (practitioner.is_check) {
            count++;
          }
        });
      }
    );

    // 専門看護師(特定行為)が選択されているなら必ず1つは選択
    if (this.IsSelectNursePractitioner && count == 0) {
      return "専門看護師(特定行為)を選択している場合は少なくとも1つは特定行為を選択してください。";
    }

    if (
      this.user.practitioner_training_completion &&
      specifiedInstitueNumCount == 0
    ) {
      return "特定行為研修の修了が「有」の場合は指定研修機関番号を入力してください。";
    }

    return true;
  }

  /** 領域パッケージ研修変更時 */
  private async changePackageTrainings(val: {
    items: AreaPackageTraining[];
    is_add: boolean;
  }) {
    // チェックを外した場合処理しない
    if (!val.is_add) {
      return;
    }

    if (
      !(await this.$openConfirm(
        "対応する特定行為を一緒にチェックします。\nよろしいですか。"
      ))
    ) {
      return;
    }

    // 選択された領域パッケージの特定行為のIDを配列にする
    const pracIds: number[] = [];
    val.items.forEach(item => {
      item.area_package_training_practitioners.forEach(prac => {
        pracIds.push(prac.practitioner_id);
      });
    });
    if (pracIds.length === 0) {
      return;
    }

    // 選択された領域パッケージの特定行為にチェック
    this.user.ewell_user_practitioner_parent_divs.forEach(pracDiv => {
      pracDiv.ewell_user_practitioners.forEach(prac => {
        if (pracIds.some(id => id === prac.practitioner_id)) {
          prac.is_check = 1;
        }
      });
    });
  }
}
