













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class extends Vue {
  @Prop({ default: "" }) private readonly title!: string;
  @Prop({ default: "" }) private readonly icon!: string;
  @Prop({ default: "" }) private readonly hint!: string;
}
