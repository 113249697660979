










import { Component, Mixins } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { agreement } from "@/types/ibow2";
import Corp from "@/components/ibow2/contractinfo/Corp.vue";
import Title from "@/components/common/Title.vue";

@Component({ components: { Corp, Title } })
export default class extends Mixins(AxiosMixin) {
  /** 法人一覧 */ private companies: agreement[] = [];

  mounted(): void {
    this.fetch();
  }

  /** データ更新 */
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/agreement/list", {}, res => {
      this.companies = res.data.agreements;
    });
  }
}
