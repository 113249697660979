









import { Component, Vue } from "vue-property-decorator";
import MyCalendar from "@/components/calendar/MyCalendar.vue";

@Component({ components: { MyCalendar } })
export default class extends Vue {}
