
































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import AppButton from "./AppButton.vue";
import AppIcon from "./AppIcon.vue";

@Component({
  components: {
    AppButton,
    AppIcon
  }
})
export default class SearchPanel extends Vue {
  @Prop({ default: "prefix" }) private prefix_id!: string;

  /** パネルの開閉状態 */
  @Prop() value!: boolean;

  /** リセットボタンのラベル */
  @Prop({ default: "リセット" }) resetLabel!: string;

  /** 検索ボタンを表示するか */
  @Prop({ default: true }) isSearch!: boolean;

  /** 検索ボタンのラベル */
  @Prop({ default: "検索する" }) searchLabel!: string;

  /** フォーム送信をするか */
  @Prop({ default: true }) isSubmit!: boolean;

  /** ドロワーを画面に被せるか */
  @Prop({ default: true }) isOverlay!: boolean;

  /** アクションエリアの有無 */
  @Prop({ default: false }) isNoAction!: boolean;

  /** 画面外クリック時に閉じない */
  @Prop({ default: false }) isPermanent!: boolean;

  /** 表示幅 */
  @Prop({ default: "500px" }) width!: string;

  /** リセット */
  @Emit() private reset() {
    return;
  }

  /** 検索 */
  @Emit() private search() {
    return;
  }

  /** value変更 */
  @Emit() private input(newValue: boolean) {
    return newValue;
  }

  private get Value() {
    return this.value;
  }

  private set Value(newValue: boolean) {
    this.input(newValue);
  }
}
