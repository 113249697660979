




























































































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Ewelluser } from "@/types/ewelluser";
import RulesMixin from "#/mixins/rulesMixin";
import { SEX_ITEMS } from "@/const";
import AppPlaces from "#/components/AppPlaces.vue";
import DateInput from "#/components/DateInput.vue";

@Component({ components: { AppPlaces, DateInput } })
export default class extends Mixins(RulesMixin) {
  /** iBowアカウント情報 */
  @Prop() private readonly user!: Ewelluser;

  /** iBowID */
  private get UserIbowId(): string {
    return this.user.user_id.replace("@", "");
  }
  private set UserIbowId(value: string) {
    this.user.user_id = "@" + value;
  }

  /** 性別マスタ */
  private sexItems = SEX_ITEMS;

  /** iBowIDのルール */
  private get IbowIdRules(): (string | boolean)[] {
    //必須ルール
    if (this.required(this.UserIbowId, "必須です") !== true) {
      return ["必須です"];
    }

    //@入力不可ルール
    if (this.UserIbowId.indexOf("@") !== -1) {
      return ["iBowIDに@を指定できません。"];
    }

    //数字のみ不可ルール
    const re = new RegExp(/^[0-9]*$/);
    if (re.test(this.UserIbowId)) {
      return ["iBowIDは1文字以上の数字以外の文字列を含む必要があります。"];
    }

    return [];
  }
}
