
























import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import { WeekIntervals } from "./common";

import AppWeekdayButton from "../AppWeekdayButton.vue";
import AppSelect from "../AppSelect.vue";

// 繰り返し毎週
@Component({
  components: {
    AppWeekdayButton,
    AppSelect
  }
})
export default class RepeatEveryWeek extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;

  // 曜日
  @Prop() week_days!: string;
  // 間隔
  @Prop() interval!: number;

  /*
   * emit
   */

  @Emit("update:week_days")
  private updateWeekDays(val: string): string {
    return val;
  }

  @Emit("update:interval")
  private updateInterval(val: number): number {
    return val;
  }

  /*
   * data
   */

  protected readonly WeekIntervals = WeekIntervals;

  /*
   * computed
   */

  private get WeekDays(): string {
    return this.week_days;
  }
  private set WeekDays(val: string) {
    this.updateWeekDays(val);
  }

  private get Interval(): number {
    return this.interval;
  }
  private set Interval(val: number) {
    this.updateInterval(val);
  }
}
