

































import { Component, Vue, Prop } from "vue-property-decorator";
import { ServiceLink } from "@/model/serviceLink/type";
import LinkCard from "@/components/serviceLink/linkCard.vue";

@Component({ components: { LinkCard } })
export default class extends Vue {
  /** サービスリンク一覧 */
  @Prop({ default: () => [] }) private serviceLinks!: ServiceLink[];
}
