















































































import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import UtilMixin from "@/mixins/utilMixin";
import { SideMenu } from "@/types";
import * as appDate from "#/utility/appDate";

@Component
export default class AppHeader extends Mixins(UtilMixin) {
  @Prop() sideMenu!: SideMenu;

  @Emit()
  private logout() {
    return;
  }

  private get IsBirthday(): boolean {
    if (this.loginUser && this.loginUser.birthday) {
      const birthdayDateObj = appDate.strToDateObj(this.loginUser.birthday);
      const nowDateObj = appDate.strToDateObj();
      return (
        birthdayDateObj.getMonth() == nowDateObj.getMonth() &&
        birthdayDateObj.getDate() == nowDateObj.getDate()
      );
    }
    return false;
  }

  private async clickLogout() {
    if (!(await this.$openConfirm("ログアウトしますか？"))) {
      return;
    }
    this.logout();
  }

  @Emit()
  private toggleSideOpen() {
    return;
  }
}
