









import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import { moji2Hankana } from "../utility/appString";
import AppTextField from "./AppTextField.vue";
import RulesMixin from "../mixins/rulesMixin";

@Component({
  components: {
    AppTextField
  }
})
export default class AppHanKanaField extends Mixins(RulesMixin) {
  @Prop() value!: string;

  @Emit() private input(value: string): string | null {
    return value;
  }

  private inputCheck() {
    const a = moji2Hankana(this.value);
    const zen = [
      "ｧ",
      "ｨ",
      "ｩ",
      "ｪ",
      "ｫ",
      "ｬ",
      "ｭ",
      "ｮ",
      "ｯ",
      "ｰ",
      "，",
      "／",
      "．",
      "（",
      "）",
      "　",
      "０",
      "１",
      "２",
      "３",
      "４",
      "５",
      "６",
      "７",
      "８",
      "９"
    ];
    const han = [
      "ｱ",
      "ｲ",
      "ｳ",
      "ｴ",
      "ｵ",
      "ﾔ",
      "ﾕ",
      "ﾖ",
      "ﾂ",
      "-",
      ",",
      "/",
      ".",
      "(",
      ")",
      " ",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9"
    ];
    let ato = "";
    for (let i = 0; i < a.length; i++) {
      let maechar = a.charAt(i);
      const zenindex = zen.indexOf(maechar);
      if (zenindex >= 0) {
        maechar = han[zenindex];
      }
      ato += maechar;
    }

    //数値補正
    this.input(ato);
  }

  private get localValue(): string {
    return this.value;
  }

  private set localValue(value: string) {
    this.input(value);
  }
}
