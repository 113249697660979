









import { Component, Mixins, Prop } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { StaffCorp } from "@/types/mypage";
import Corp from "./officelist/Corp.vue";

@Component({ components: { Corp } })
export default class extends Mixins(AxiosMixin) {
  @Prop({ default: () => [] }) private corps!: StaffCorp[];

  mounted(): void {
    this.fetch();
  }

  //データ更新
  private fetch() {
    this.postJsonCheck(window.base_url + "/api/office/list", {}, res => {
      this.corps = res.data.corps;
    });
  }
}
