




























































import { Component, Prop, Vue, Ref, Emit } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import BiometricRegistDialog from "@/components/biometric/BiometricRegistDialog.vue";

@Component({
  components: { BiometricRegistDialog }
})
export default class extends Vue {
  @Prop({ default: () => DefaultEwelluser() }) private user!: Ewelluser;

  @Emit() private update(): void {
    return;
  }

  @Ref("BiometricRegistDialog")
  private readonly biometricRegistDialog!: BiometricRegistDialog;

  /** 生体認証情報登録 */
  private saveBiometricAuth() {
    this.biometricRegistDialog.open(false);
  }
}
