








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class extends Vue {
  /** ラベル */
  @Prop({ default: "" }) private readonly label!: string;
  /** 値 */
  @Prop({ default: "" }) private readonly value!: string;
}
