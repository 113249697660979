














import { Component, Prop, Vue } from "vue-property-decorator";
import { Ewelluser } from "@/types/ewelluser";
import PractitionerTable from "@/components/userinfo/edit/mypageitem/practitioner/PractitionerTable.vue";
import PractitionerNumber from "./practitioner/PractitionerNumber.vue";

@Component({ components: { PractitionerTable, PractitionerNumber } })
export default class extends Vue {
  @Prop() private readonly user!: Ewelluser;
}
