import { MutationTree } from "vuex";
import {
  RootState,
  SET_IS_EDIT,
  SET_IS_REGISTERD_AUTH_NAVIGATION,
  SET_IS_VISIT_NAVIGATION
} from "./types";
import {
  SET_IS_SAVE,
  SET_IS_PROCESSING,
  SET_IS_BTN_CLICK
} from "#/store/types";

export const mutations: MutationTree<RootState> = {
  [SET_IS_EDIT](state, is_edit: boolean) {
    state.is_edit = is_edit;
  },
  [SET_IS_REGISTERD_AUTH_NAVIGATION](
    state,
    is_registerd_auth_navigation: boolean
  ) {
    state.is_registerd_auth_navigation = is_registerd_auth_navigation;
  },
  [SET_IS_VISIT_NAVIGATION](state, is_visit_navigation: boolean) {
    state.is_visit_navigation = is_visit_navigation;
  },
  [SET_IS_SAVE](state, is_save: boolean) {
    state.is_save = is_save;
  },
  [SET_IS_PROCESSING](state, is_processing: boolean) {
    state.is_processing = is_processing;
  },
  [SET_IS_BTN_CLICK](state, is_btn_click: boolean) {
    state.is_btn_click = is_btn_click;
  }
};
