







import { Component, Vue } from "vue-property-decorator";
import SecurityCode from "@/components/userinfo/info/change/SecurityCode.vue";

@Component({ components: { SecurityCode } })
export default class extends Vue {}
