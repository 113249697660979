

























import { Component, Mixins, Vue, Ref } from "vue-property-decorator";
import Main from "@/components/app/Main.vue";
import Login from "@/components/app/Login.vue";
import Cookies from "js-cookie";
import AxiosMixin from "@/mixins/axiosMixin";
import Password from "@/components/app/Password.vue";
import { uuid } from "vue-uuid";
import AppConfirm from "#/components/AppAlertConfirm.vue";
import BiometricRegistDialog from "@/components/biometric/BiometricRegistDialog.vue";

window.axios = require("axios");

window.base_url = process.env.VUE_APP_BACKEND_URL;
if (!window.base_url) {
  window.base_url = "http://localhost:8085";
}

window.middleend_url = process.env.VUE_APP_MIDDLEEND_URL;
if (!window.middleend_url) {
  window.middleend_url = "http://localhost:8085";
}

window.ibow_frontend_url = process.env.VUE_APP_IBOW_FRONTEND_URL;
if (!window.ibow_frontend_url) {
  window.ibow_frontend_url = "http://localhost:9000";
}

window.auth_frontend_url = process.env.VUE_APP_AUTH_FRONTEND_URL;
if (!window.auth_frontend_url) {
  window.auth_frontend_url = "http://localhost:9200";
}

window.cookie_domain = process.env.VUE_APP_COOKIE_DOMAIN;
if (!window.cookie_domain) {
  window.cookie_domain = "localhost";
}

window.app_version = process.env.VUE_APP_VERSION;
if (!window.app_version) {
  window.app_version = "A";
}

//ポータルURL
window.portal_frontend_url = process.env.VUE_APP_PORTAL_FRONTEND_URL;
if (!window.portal_frontend_url) {
  window.portal_frontend_url = "http://localhost:3000";
}

window.axios.defaults.headers.common["APP_VERSION"] = window.app_version;

@Component({
  components: {
    Main,
    Login,
    Password,
    AppConfirm,
    BiometricRegistDialog
  }
})
export default class App extends Mixins(AxiosMixin) {
  protected isToken = false; //トークン認証済みかどうか
  protected passwordResetType = 0; //1:パスワードリセット 2:セキュリティコード 3:新パスワード入力

  @Ref("BiometricRegistDialog")
  private readonly biometricRegistDialog!: BiometricRegistDialog;

  @Ref("app_confirm") private readonly appConfirm!: AppConfirm;

  created(): void {
    //フィンガープリント
    const fingerprint = Cookies.get("fingerprint");
    if (fingerprint) {
      window.fingerprint = fingerprint;
    } else {
      //ランダム文字列ない場合は作る
      window.fingerprint = uuid.v4();
    }
    Cookies.set("fingerprint", window.fingerprint, {
      domain: window.cookie_domain,
      expires: 3650
    });

    const token = Cookies.get("token");
    if (token) {
      window.token = token;
      this.isToken = true;
    }
    const reftoken = Cookies.get("reftoken");
    if (reftoken) {
      window.reftoken = reftoken;
    }
  }

  mounted() {
    // this使えないので、参照を保持
    const dialog = this.appConfirm;

    // アラートダイアログ呼び出し用インスタンスプロパティ作成
    Vue.prototype.$openAlert = async function(text: string): Promise<boolean> {
      dialog.isConfirm = false;
      const res = await dialog.open(text);
      return res;
    };
    // 確認ダイアログ呼び出し用インスタンスプロパティ作成
    Vue.prototype.$openConfirm = async function(
      text: string,
      saveLabel = "OK",
      cancelLabel = "キャンセル"
    ): Promise<boolean> {
      dialog.isConfirm = true;
      const res = await dialog.open(text, saveLabel, cancelLabel);
      return res;
    };

    // iBow側から遷移時、生体認証の登録がなければ、登録ダイアログを表示する
    const notRegisterd = Cookies.get("not_webauthn_device_registerd");
    if (notRegisterd == "1") {
      Cookies.set("not_webauthn_device_registerd", "0", {
        domain: window.cookie_domain
      });
      this.openBiometricAuthRegist();
    }
  }

  /** ログアウト */
  private logout() {
    this.postJsonCheck(window.base_url + "/api/auth/logout", {}, () => {
      window.token = "";
      Cookies.set("token", "", { domain: window.cookie_domain });
      window.reftoken = "";
      Cookies.set("reftoken", "", { domain: window.cookie_domain });
      location.href = "/";
    });
  }

  //ログイン後の画面へ
  private login(isOpenRegistDialog = false) {
    this.isToken = true;
    if (isOpenRegistDialog) {
      this.openBiometricAuthRegist();
    }
  }

  //パスワードリセット画面へ
  private passwordReset() {
    this.passwordResetType = 1;
  }

  /** 生体認証登録画面を開く */
  private openBiometricAuthRegist() {
    const notNeed = localStorage.getItem("not-need-biometric-auth-regist");
    if (notNeed != "1") {
      this.biometricRegistDialog.open();
    }
  }
}
