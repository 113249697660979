






























































































































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Ewelluser } from "@/types/ewelluser";
import PrivateSwitch from "@/components/userinfo/publish/settingarea/PrivateSwitch.vue";

@Component({ components: { PrivateSwitch } })
export default class extends Vue {
  @Prop() value!: Ewelluser;
  @Emit("input") input(user: Ewelluser) {
    return user;
  }
  private get User() {
    return this.value;
  }
  private set User(user: Ewelluser) {
    this.input(user);
  }
}
