





























import { Component, Vue, Prop } from "vue-property-decorator";
import { agreement } from "@/types/ibow2";

@Component
export default class ContractInfo extends Vue {
  /** 法人 */
  @Prop({ default: () => ({}) }) private readonly company!: agreement;
}
