export const EventType = {
  /** 職員予定 */ StaffSchedule: 1,
  /** 訪問予定(職員あり) */ VisitSchedule: 2,
  /** シフト */ Shift: 3,
  /** 勤怠シフト */ AttendanceShift: 4,
  /** 休日 */ Holiday: 5,
  /** アカウント予定 */ AccountSchedule: 6,
  /** 訪問予定(職員なし) */ VisitScheduleNoStaff: 7,
  /** 利用者個別予定 */ IndivisualSchedule: 8,

  /** 看護記録書２介護 */ Record2NursingCare: 101,
  /** 看護記録書２医療 */ Record2Medical: 102,
  /** 看護記録書２定期巡回 */ Record2PassCrawl: 103,
  /** 普通指示書 */ DirectionNormal: 104,
  /** 特別指示書 */ DirectionSpecial: 105,
  /** 定期巡回 */ PassCrawl: 106,
  /** 不在 */ Absence: 107,
  /** 介護保険 */ Care: 109,
  /** 負担割合証 */ BurdenRatio: 110,
  /** 医療保険 */ Medical: 111,
  /** 高額療養費 */ HighExpense: 112,
  /** 高齢受給者証 */ OldAge: 113,
  /** 公費 */ PublicMoney: 114,
  /** 定期巡回除外日 */ PassCrawlExceptDate: 115,
  /** 指定月以外の訪問 */ OutMonthVisit: 116,
  /** ファイル登録 */ File: 117,
  /** 利用者状況 */ Situation: 118
};

export type EventType = typeof EventType[keyof typeof EventType];
