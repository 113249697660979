

























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { MonthIntervals, Weeks } from "./common";
import AppToggleButton from "../AppToggleButton.vue";
import AppWeekdayButton from "../AppWeekdayButton.vue";

// 繰り返し毎月（週指定）
@Component({
  components: {
    AppWeekdayButton,
    AppToggleButton
  }
})
export default class RepeatMonthlyWeekAssign extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  // 週（X週）
  @Prop() weeks!: string;
  // 曜日
  @Prop() week_days!: string;
  // 間隔
  @Prop() interval!: number;

  /*
   * emit
   */

  @Emit("update:week_days")
  private updateWeekDays(val: string): string {
    return val;
  }
  @Emit("update:weeks")
  private updateWeeks(val: string): string {
    return val;
  }
  @Emit("update:interval")
  private updateInterval(val: number): number {
    return val;
  }

  /*
   * data
   */

  private readonly MONTH_INTERVALS = MonthIntervals;
  private readonly WEEKS = Weeks;

  /*
   * computed
   */

  private get Weeks(): string {
    return this.weeks;
  }
  private set Weeks(val: string) {
    this.updateWeeks(val);
  }

  private get WeekDays(): string {
    return this.week_days;
  }
  private set WeekDays(val: string) {
    this.updateWeekDays(val);
  }

  private get Interval(): number {
    return this.interval;
  }
  private set Interval(val: number) {
    this.updateInterval(val);
  }

  // /*
  //  * method
  //  */

  // // 週set,get
  // private getWeek(index: number): number {
  //   return Number(this.weeks[index]);
  // }
  // private setWeek(index: number, event: string) {
  //   const weeks = this.weeks.split("");
  //   weeks[index] = event;
  //   for (const week of weeks) {
  //     if (week == "1") {
  //       this.updateWeeks(weeks.join(""));
  //       break;
  //     }
  //   }
  // }
}
