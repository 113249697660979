export const CERTIFICATION = {
  /** 資格 1:看護師 */
  REGISTERED_NURSE: 1,
  /** 資格 2:准看護師 */
  PRACTICAL_NURSE: 2,
  /** 資格 3:専門看護師（緩和ケア） */
  SPECIALITY_NURSE_MITIGATION: 3,
  /** 資格 4:専門看護師（褥瘡ケア） */
  SPECIALITY_NURSE_BEDSORE: 4,
  /** 資格 5:専門看護師（人工肛門／膀胱ケア） */
  SPECIALITY_NURSE_URINARY_BLADDER: 5,
  /** 資格 6:保健師 */
  PUBLIC_HEALTH_NURSE: 6,
  /** 資格 7:助産師 */
  MIDWIFE: 7,
  /** 資格 8:理学療法士(PT) */
  PT: 8,
  /** 資格 9:作業療法士(OT) */
  OT: 9,
  /** 資格 10:言語聴覚士(ST) */
  ST: 10,
  /** 資格 11:ケアマネジャー */
  CARE_MANAGER: 11,
  /** 資格 12:看護補助 */
  NURSE_ASSISTANT: 12,
  /** 資格 13:看護学生 */
  NURSE_STUDENT: 13,
  /** 資格 14:精神保健福祉士(PSW) */
  PSW: 14,
  /** 資格 15:その他 */
  OTHER: 15,
  /** 資格 16:専門看護師（看取り） */
  SPECIALITY_NURSE_END_OF_LIFE_CARE: 16,
  /** 資格 17:専門看護師（特定） */
  NURSSE_PRACTITIONER: 17
};
