

























import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import { WeekDayTimes, MonthIntervals } from "./common";

import AppToggleButton from "../AppToggleButton.vue";
import AppWeekdayButton from "../AppWeekdayButton.vue";

// 繰り返し毎月（曜日指定）
@Component({
  components: {
    AppWeekdayButton,
    AppToggleButton
  }
})
export default class RepeatMonthlyWeekDayAssign extends Vue {
  /*
   * prop
   */

  @Prop({ default: "prefix" }) private prefix_id!: string;
  // 曜日（X回目）
  @Prop() week_times!: string;
  // 曜日
  @Prop() week_days!: string;
  // 間隔
  @Prop() interval!: number;

  /*
   * emit
   */

  @Emit("update:week_times")
  private updateWeekTimes(val: string): string {
    return val;
  }
  @Emit("update:week_days")
  private updateWeekDays(val: string): string {
    return val;
  }
  @Emit("update:interval")
  private updateInterval(val: number): number {
    return val;
  }

  /*
   * data
   */

  private readonly WeekDayTimes = WeekDayTimes;
  private readonly MonthIntervals = MonthIntervals;

  /*
   * computed
   */

  private get WeekTimes(): string {
    return this.week_times;
  }
  private set WeekTimes(val: string) {
    this.updateWeekTimes(val);
  }

  private get WeekDays(): string {
    return this.week_days;
  }
  private set WeekDays(val: string) {
    this.updateWeekDays(val);
  }

  private get Interval(): number {
    return this.interval;
  }
  private set Interval(val: number) {
    this.updateInterval(val);
  }
}
