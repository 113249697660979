















import { Component, Emit, Prop, Mixins } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppRadioGroup extends Mixins(RulesSaveMixin) {
  /** 値 */
  @Prop() private readonly value!: unknown;

  /** 値変更時 */
  @Emit() input(newValue: unknown) {
    return newValue;
  }

  /** 値 */
  private get Value(): unknown {
    return this.value;
  }

  /** 値 */
  private set Value(newValue: unknown) {
    this.input(newValue);
  }
}
