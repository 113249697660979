import Vue from "vue";
import Component from "vue-class-component";
import { AxiosResponse, AxiosError, AxiosRequestConfig } from "axios";
import Cookies from "js-cookie";

@Component
export default class AxiosMixin extends Vue {
  /** タイムアウト時間 */
  timeout = 2000;

  /** ローディング状態 trueの時、ローディングをしている */
  loading = false;

  /** リロード回数 */
  reloadCount = Number(window.sessionStorage.getItem("reload-count"));

  public postJson(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError) => void,
    option: AxiosRequestConfig,
    blobRes: boolean
  ): void {
    this.loading = true;
    if (process.env.VUE_APP_MODE == "debug") {
      option.headers = {
        ...option.headers,
        AuthorizationIbow: "Bearer " + window.token,
        ReloadCount: this.reloadCount
      };
    } else {
      option.headers = {
        ...option.headers,
        Authorization: "Bearer " + window.token,
        ReloadCount: this.reloadCount
      };
    }
    window.axios
      .post(url, param, option)
      .then((response: AxiosResponse) => {
        this.loading = false;

        // Blue/Green対応
        if (response.data && response.data.code === 999) {
          this.reloadCount++;
          window.sessionStorage.setItem(
            "reload-count",
            String(this.reloadCount)
          );
          location.reload();
          return;
        }

        sucessFunc(response);
      })
      .catch(async (error: AxiosError) => {
        if (error.response && error.response.status == 401) {
          if (error.response.data.auth_code == 1) {
            await this.$openAlert(error.response.data.Error);
            this.$router.go(-1);
          } else {
            this.refToken(url, param, blobRes, sucessFunc, catchFunc);
          }
        } else {
          this.loading = false;
          console.log(error?.response?.data);
          this.showErrorMsg(error);
        }
        catchFunc(error);
      });
  }

  public postJsonCheck(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError) => void = () => {
      return;
    }
  ): void {
    const func = async (response: AxiosResponse) => {
      if (response.data.code > 0 && response.data.code < 999) {
        await this.$openAlert(response.data.message);
      } else {
        sucessFunc(response);
      }
    };
    this.postJson(url, param, func, catchFunc, {}, false);
  }

  public postJsonBlobResCheck(
    url: string,
    param: unknown,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError) => void
  ): void {
    const func = async (response: AxiosResponse) => {
      if (
        response.data.code &&
        response.data.code > 0 &&
        response.data.code < 999
      ) {
        await this.$openAlert(response.data.message);
      } else {
        sucessFunc(response);
      }
    };
    this.postJson(url, param, func, catchFunc, { responseType: "blob" }, true);
  }

  public postJsonBackground(
    url: string,
    param: object,
    sucessFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError) => void = () => {
      return;
    }
  ): void {
    this.postJson(
      url,
      param,
      sucessFunc,
      catchFunc,
      {},
      false //エラー時にアラートを表示しない
    );
  }

  private async showErrorMsg(error: AxiosError): Promise<void> {
    let msg = "サーバでエラーが発生しました。";
    if (error.response) {
      switch (error.response.status) {
        case 429: // Too Many Requests
          msg =
            "サーバーが大変混み合っています。申し訳ありませんが、しばらくたってからやり直してください。";
          break;
      }
      if (error.response.data?.code == 101) {
        msg = error.response.data?.message;
      }
    }
    await this.$openAlert(msg);
  }

  private refToken(
    url: string,
    param: unknown,
    blobRes: boolean,
    successFunc: (res: AxiosResponse) => void,
    catchFunc: (error: AxiosError) => void
  ) {
    window.axios
      .post(window.base_url + "/auth/refresh", {
        ref_token: window.reftoken
      })
      .then((response: AxiosResponse) => {
        window.token = response.data.token;
        Cookies.set("token", response.data.token, {
          domain: window.cookie_domain
        });
        window.reftoken = response.data.ref_token;
        Cookies.set("reftoken", response.data.ref_token, {
          domain: window.cookie_domain
        });
        if (blobRes) {
          this.postJsonBlobResCheck(url, param, successFunc, catchFunc);
        } else {
          this.postJsonCheck(url, param, successFunc, catchFunc);
        }
      })
      .catch(async (error: AxiosError) => {
        this.loading = false;
        if (error.response && error.response.status == 401) {
          await this.$openAlert("セッションがタイムアウトになりました。");
          window.token = "";
          Cookies.set("token", "", {
            domain: window.cookie_domain
          });
          window.reftoken = "";
          Cookies.set("reftoken", "", {
            domain: window.cookie_domain
          });
          location.href = "/";
        } else {
          this.showErrorMsg(error);
        }
      });
  }
}
