

























import { Component, Vue, Prop } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import UserInfoItem from "@/components/userinfo/info/userInfoArea/UserInfoItem.vue";

@Component({
  components: { UserInfoItem }
})
export default class extends Vue {
  @Prop({ default: () => DefaultEwelluser() }) private user!: Ewelluser;
}
