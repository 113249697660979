import Base from "../base";

/** イベント種別 */
export const LAYER_ID = {
  /** 国民の祝日 */ HOLIDAY: 1,
  /** シフト */ SHIFT: -1,
  /** 訪問予定 */ VISIT: -2,
  /** 参加 */ PARTICIPANT: -3
};

export interface DispParticipant {
  id: number;
  staff_id: number; // 職員ID
  event_id: number; // イベントID
  name: string; // 参加者名
  status: number; // 状態
}
export const DefaultDispParticipant = (): DispParticipant => ({
  id: 0,
  staff_id: 0, // 職員ID
  event_id: 0, // イベントID
  name: "", // 参加者名
  status: 0 // 状態
});

// 表示用イベント
export interface DispEvent {
  /** イベントID */ id: number;
  /** レイヤーID */ layer_id: number;
  /** 利用者ID */ patient_id?: number;
  /** 表示テキスト */ name: string;
  /** カラーコード */ color: string;
  /** 開始 */ start: string;
  /** 終了 */ end: string;
  /** 時間指定フラグ */ timed: number;
  /** タイトル */ title: string;
  /** 本文 */ content: string;
  /** 場所 */ locate: string;
  /** 編集不可フラグ */ invalid_edit: number;
  /** 状態 0:キャンセル 1:有効 */ status: number;
  /** 参加者リスト */ participants: DispParticipant[];
  /** 繰り返しイベントフラグ */ is_repeat: number;
  /** クリック不可能なイベントかどうか */ is_not_click: boolean;
  /** 時間増減可能なイベントかどうか */ is_extend_time: boolean;
  /** 移動可能なイベントかどうか */ is_move: boolean;
}
export const DefaultDispEvent = (): DispEvent => ({
  id: 0,
  layer_id: 0,
  patient_id: 0,
  name: "",
  color: "",
  start: "",
  end: "",
  timed: 0,
  title: "",
  content: "",
  locate: "",
  invalid_edit: 0,
  status: 0,
  participants: [],
  is_repeat: 0,
  is_not_click: false,
  is_extend_time: false,
  is_move: false
});

// カレンダーレイヤーインターフェース
export interface CalendarLayer extends Base {
  /** レイヤーID */ id: number;
  /** eWeLLユーザーID */ ewell_user_id: number;
  agreement_id: number; // 契約ID
  staff_id: number; // 職員ID
  name: string; // レイヤー名
  color: string; // カラーコード
  invalid_edit: number; // 編集不可フラグ
  public: number; // 公開カレンダーフラグ
  events: DispEvent[];
}
export const DefaultCalendarLayer = (): CalendarLayer => ({
  id: 0,
  ewell_user_id: 0,
  agreement_id: 0,
  staff_id: 0,
  name: "",
  color: "",
  invalid_edit: 0,
  public: 0,
  events: [],
  created_at: "",
  updated_at: "",
  deleted_at: ""
});

// イベント有効状態
export const EVENT_STATUS_TYPE = {
  DELETED: 0, // 無効
  VALID: 1 // 有効
};
// イベント参加・表示状態
export const EVENT_DISP_TYPE = {
  UNDECIDED: 0, // 未定
  NO_PART: 1, // 参加しない
  PART: 2, // 参加する
  DELETED: 3 // 削除された予定
};

// 表示用イベント
export interface DispCalendarEvent {
  /** イベントID */ id: number;
  /** レイヤーID */ layer_id: number;
  /** 職員ID */ staff_id: number;
  /** 表示テキスト */ name: string;
  /** カラーコード */ color: string;
  start: string; // 開始
  end: string; // 終了
  timed: number; // 終日
  title: string; // タイトル
  title_tool: string; // タイトル（ツールチップ）
  content: string; // 本文
  locate: string; // 場所
  invalid_edit: number; // 編集不可フラグ
  event_status: number; // 状態 0:キャンセル 1:有効
  all_participation_status: Status[];
  own_participant: DispParticipant; // 自分の参加状況
  is_repeat: number; // 繰り返しイベントフラグ
  is_not_click: boolean; //クリック不可能なイベントかどうか
  is_extend_time: boolean; //時間増減可能なイベントかどうか
  is_move: boolean; //移動可能なイベントかどうか
  /** ボタン種類 1:編集ボタン 2:参加ボタン */ button_type?: number;
  is_cancel?: number;
}
export const DefaultDispCalendarEvent = (): DispCalendarEvent => ({
  id: 0,
  layer_id: 0,
  staff_id: 0,
  name: "",
  color: "",
  start: "",
  end: "",
  timed: 0,
  title: "",
  title_tool: "",
  content: "",
  locate: "",
  invalid_edit: 0,
  event_status: 0,
  all_participation_status: [],
  own_participant: DefaultDispParticipant(),
  is_repeat: 0,
  is_not_click: false,
  is_extend_time: false,
  is_move: false
});

// カレンダーレイヤーインターフェース
export interface StaffCalendarLayer {
  id: number; // レイヤーID
  ewell_user_id: number; // ewellユーザーID
  staff_id: number; // 職員ID
  name: string; // レイヤー名
  color: string; // カラーコード
  invalid_edit: number; // 編集不可フラグ
  public: number; // 公開カレンダーフラグ
  events: DispCalendarEvent[];
}
export const DefaultStaffCalendarLayer = (): StaffCalendarLayer => ({
  id: 0,
  ewell_user_id: 0,
  staff_id: 0,
  name: "",
  color: "",
  invalid_edit: 0,
  public: 0,
  events: []
});

export interface Status {
  staff_id: number;
  name: string;
  status: number;
}
