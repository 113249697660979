














import { Component, Vue, Prop } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";

@Component
export default class extends Vue {
  @Prop({ default: () => DefaultEwelluser() }) private user!: Ewelluser;
}
