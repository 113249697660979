
/** リンクタイプ */
export const LINK_TYPE = {
  /** 直リンク*/
  DIRECT: 1,
  /** お問合せフォーム */
  MAIL: 2,
  /** 中間ページ */
  INTERMEDIATE: 3
};
