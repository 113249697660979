



















import { Component, Emit, Mixins, Prop } from "vue-property-decorator";
import RulesSaveMixin from "../mixins/rulesSaveMixin";

@Component
export default class AppColorPicker extends Mixins(RulesSaveMixin) {
  /** ボタンラベル */
  @Prop({ default: "" }) label!: string;

  /** カラーコード 16進数 v-modelで設定可能 */
  @Prop({ default: "" }) value!: string;

  /** カラー変更時 */
  @Emit() private input(val: string) {
    return val;
  }
}
