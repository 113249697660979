

























import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import CalendarFocusChange from "./CalendarFocusChange.vue";
import AppIcon from "./../AppIcon.vue";
import { Calendar } from "./common";

@Component({
  components: {
    AppIcon,
    CalendarFocusChange
  }
})
export default class CalendarTitleBeforeAfter extends Vue {
  /** カレンダーコンポーネント */
  @Prop({ default: () => ({}) }) calendar!: Calendar;

  /** カレンダータイプ */
  @Prop({ default: "" }) type!: string;

  /** 指定年月日 */
  @Prop({ default: "" }) value!: string;

  /** 指定年月日の変更時 */
  @Emit("change") private emitChange() {
    return;
  }

  /** 指定年月日の変更時 */
  @Emit("input") private emitInput(value: string) {
    return value;
  }

  /** 指定年月日 */
  private get Value() {
    return this.value;
  }

  /** 指定年月日 */
  private set Value(value: string) {
    this.emitInput(value);
  }

  /** 表記月 */
  private get TitleMonth() {
    const title = this.calendar.title;

    if (!title) {
      return "";
    }

    const position = title.lastIndexOf("月");
    if (position < 0) {
      return "";
    }

    return title.slice(0, position + 1);
  }

  /** 表記年 */
  private get TitleYear() {
    const title = this.calendar.title;

    if (!title) {
      return "";
    }

    const position = title.lastIndexOf("月");
    if (position < 0) {
      return "";
    }

    return title.slice(position + 1) + "年";
  }

  /** 前月 */
  prev() {
    this.calendar.prev();
    this.emitChange();
  }

  /** 次月 */
  next() {
    this.calendar.next();
    this.emitChange();
  }
}
