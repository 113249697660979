export const SET_IS_EDIT = "SET_IS_EDIT";

export const SET_IS_REGISTERD_AUTH_NAVIGATION =
  "SET_IS_REGISTERD_AUTH_NAVIGATION";

export const SET_IS_VISIT_NAVIGATION = "SET_IS_VISIT_NAVIGATION";

export interface RootState {
  version: string; //バージョン
  is_edit: boolean; //編集状態
  is_registerd_auth_navigation: boolean; //権限ナビゲーション登録フラグ
  is_visit_navigation: boolean; //訪問ナビゲーション登録フラグ
  is_save: boolean; //保存かどうか
  is_btn_click: boolean; //ボタンクリックされたかどうか
  is_processing: boolean; //進行中かどうか（ボタン用）
}
