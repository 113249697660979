















import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { StaffCorp } from "@/types/mypage";
import Staff from "./Staff.vue";

@Component({ components: { Staff } })
export default class extends Vue {
  @Prop({ default: () => ({}) }) private corp!: StaffCorp;

  /** データ更新 */
  @Emit() private update() {
    return;
  }
}
