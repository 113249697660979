


















































import { Component, Emit, Mixins, Ref } from "vue-property-decorator";
import { AxiosError } from "axios";
import RulesMixin from "#/mixins/rulesMixin";

@Component
export default class PasswordRemindChange extends Mixins(RulesMixin) {
  @Ref("form")
  private readonly form!: { validate: () => boolean };

  private password_show = false; //新しいパスワード表示フラグ
  private confirm_password_show = false; //確認用パスワード表示フラグ
  private password = ""; //新しいパスワード
  private confirm_password = ""; //確認用パスワード
  private loading = false; //ローディング
  passwordMatch = /(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[#$%])/; //パスワードの正規表現

  //パスワードチェンジ
  private async changePassword() {
    if (!this.form.validate()) {
      return;
    }
    if (!this.password.match(this.passwordMatch) || this.password.length < 7) {
      await this.$openAlert(
        "パスワードには半角英数の大文字、小文字、記号（#$%）各1つ以上を含め、8文字以上で入力してください。"
      );
      return;
    }
    if (
      !(await this.$openConfirm("パスワードを変更します。よろしいですか？"))
    ) {
      return;
    }
    this.loading = true;
    window.axios
      .post(
        window.base_url + "/api/password-reset/reset",
        {
          password: this.password,
          confirm_password: this.confirm_password
        },
        {
          headers: {
            Authorization: "Bearer " + window.tmp_token
          }
        }
      )
      .then(() => {
        this.loading = false;
        location.href = "/";
      })
      .catch(async (error: AxiosError) => {
        this.loading = false;
        if (error.response && error.response.status == 401) {
          await this.$openAlert(
            "有効期限が切れました。初めからやり直してください。"
          );
        } else {
          console.log(error.response?.data);
          if (error.response?.data?.message) {
            await this.$openAlert(error.response.data.message);
          }
        }
      });
  }
}
