
































import { Component, Mixins, Ref } from "vue-property-decorator";
import AxiosMixin from "@/mixins/axiosMixin";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import SettingArea from "@/components/userinfo/publish/SettingArea.vue";
import { VForm } from "#/components/FileUpload.vue";
import Title from "@/components/common/Title.vue";

@Component({ components: { SettingArea, Title } })
export default class extends Mixins(AxiosMixin) {
  @Ref("form") private readonly form!: VForm;

  private user: Ewelluser = DefaultEwelluser();

  mounted(): void {
    this.fetch();
  }

  /** データ更新 */
  private fetch() {
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/publish-setting/get",
      {},
      res => {
        this.user = res.data.ewell_user;
      }
    );
  }

  /** データ保存 */
  private async saveData() {
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/publish-setting/save",
      {
        ewell_user: this.user
      },
      () => {
        this.$openAlert("登録しました。").then(() => {
          this.fetch();
        });
      }
    );
  }

  //戻る処理
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/userinfo");
    }
  }
}
