
















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Link } from "../types";

@Component
export default class AppSideBar extends Vue {
  @Prop({ default: () => [] }) links!: Link[]; //リンクリスト

  @Emit("clickItem")
  clickItem(index: number, link: Link) {
    return { index: index, link: link };
  }

  private getBarText(link: Link) {
    if (link.mini_text) {
      return link.mini_text;
    }
    return link.text;
  }
}
